import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // thisVue : {},
    token: null,
    language: null,
    formdata: null,
    country_allow_transit_and_stay: [
      'Andorra',
      'Argentina',
      'Austria',
      'Bahrain',
      'Belgium',
      'Brazil',
      'British citizen',
      'British National (Overseas)',
      'British subject',
      'Brunei Darussalam',
      'Bulgaria',
      'Canada',
      'Chile',
      'Croatia',
      'Cyprus',
      'Czechia [Czech Republic]',
      'Denmark',
      'Estonia',
      'Finland',
      'France',
      'Germany',
      'Greece',
      'Hong Kong (SAR China)',
      'Hungary',
      'Iceland',
      'Ireland (Republic of)',
      'Israel',
      'Italy',
      'Japan',
      'Korea, Republic of [South Korea]',
      'Kuwait',
      'Latvia',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macao (SAR China)',
      'Malaysia',
      'Malta',
      'Mauritius',
      'Mexico',
      'Monaco',
      'Netherlands',
      'Norway',
      'Oman',
      'Poland',
      'Portugal',
      'Qatar',
      'Romania',
      'San Marino',
      'Saudi Arabia',
      'Seychelles',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Spain',
      'Sweden',
      'Switzerland',
      'Taiwan',
      'United Arab Emirates',
      'United Kingdom of Great Britain and Northern Ireland',
      'United Nations Organisation',
      'United States of America',
      'Uruguay',
      'Vatican City State [Holy See]',
    ],
    // country_no_medication: [
    //   'Afghanistan', 'Albania', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Armenia',
    // ]
  },
  mutations: {
  //   setThisVue(state, payload){
  //     state.thisVue = payload;
  // },
  setToken(state, data){
    state.token = data
  },
  setLanguage(state, data){
    state.language = data
  },
  setFormdata(state, data){
    state.formdata = data
  }
  },
  actions: {
  //   setThisVue(context, payload){
  //     context.commit('setThisVue', payload);
  // },
  setToken(context, data){
    // let _this = context.state.thisVue;
    context.commit('setToken', data);
  },
  setLanguage(context, data){
    // let _this = context.state.thisVue;
    context.commit('setLanguage', data);
  },
  setFormdata(context, data){
    // let _this = context.state.thisVue;
    context.commit('setFormdata', data);
  }
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})