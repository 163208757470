<template>
  <div>
    <div>
    <!-- <v-app-bar
      absolute
      color="white"
      inverted-scroll
      scroll-target="#scrolling-techniques-7"
    >

      <v-toolbar-title><v-img src="../../assets/img/nzeta-logo.png" width="140"></v-img></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="showTranslator">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar> -->
    <div class="outer-content">
    <div class="bg-content"></div>
      <div class="inner-content">
       <v-toolbar elevation="0" class="pa-2" color="transparent" max-height="45">
        <v-img src="../../assets/img/nzeta-logo.png" max-width="140"></v-img>
        <v-spacer></v-spacer>
      <!-- <v-chip @click="showTranslator" outlined color="#dc2a5f"><span class="notranslate">{{cur_language}}</span></v-chip> -->
      <Translation v-if="render" />
       </v-toolbar>
        <div class=" text-h4 text-md-h2 text-center title">
            New Zealand Electronic Travel Authority (NZeTA)
        </div>
        <div class="text-center desc font-weight-light">
            Use this form to request an NZeTA (New Zealand Electronic Travel Authority) and pay for an International Visitor Conservation and Tourism Levy (IVL).
        </div>
        <v-row class="requirements justify-center">
            <v-list dense class="req-list">
      <div class="text-center text-sm-h6 mb-3">You need:</div>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">{{item.text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
    </v-list>
        </v-row>
      </div>
    </div>
      <div class="content">
        <section class="section">
    <div class="container">
        <div class="columns">
            <div class="column is-8 is-offset-2 pt-3">
                <horizontal-stepper v-if="render" :steps="demoSteps" @completed-step="completeStep" class="pt-5" :top-buttons="showButtons"
                                    @active-step="isStepActive" @stepper-finished="finish"
                >                     
                </horizontal-stepper>
            </div>
        </div>
    </div>
</section>
<Footer />
      </div>
      <!-- <v-card class="content elevation-0"> -->
    <!-- <v-card-title>
      Request your NZeTA
    </v-card-title>

    <v-card-subtitle>
      If your passport details are not correct you may not be able to travel.
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        color="orange lighten-2"
        text
      >
        Explore
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card> -->
    <v-dialog
      v-model="showResult"
      width="500"
      scrollable
    >

      <v-card>
        <v-card-title class="text-h5">
          Confirmation
          <v-spacer></v-spacer>
          <v-btn icon @click="showResult = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text style="max-height: 900px">
          <!-- <pre>
          {{JSON.stringify(formdata_final, null, 2)}}
          </pre> -->
          Are you sure you want to submit this application?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#dc2a5f"
            class="white--text elevation-0 rounded-0"
            @click="confirmApplication()"
            :loading="isSubmitting"
          >
          <v-icon class="mr-1">mdi-check-circle-outline</v-icon>
            submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
    <LoadingScreen v-show="isSubmitting" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
import StepOne from '../../components/Forms/StepOne.vue';
import StepTwo from '../../components/Forms/StepTwo.vue';
import StepThree from '../../components/Forms/StepThree.vue';
import Translation from '../../components/Utils/Translation.vue';
import LoadingScreen from '../../components/Utils/LoadingScreen2.vue';
import Footer from '../../components/Footer/Footer.vue';
export default {
  name: "Home",
  components: {
    Translation,
    LoadingScreen,
    Footer,
  },
  data: () => ({
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    demoSteps: [
        {
            icon: 'mdi-account-question',
            name: 'first',
            title: 'Passport and Eligibility',
            subtitle: 'Step One',
            component: StepOne,
            completed: false

        },
        {
            icon: 'mdi-card-account-details',
            name: 'second',
            title: 'Traveller Details',
            subtitle: 'Step Two',
            component: StepTwo,
            completed: false
        },
        {
            icon: 'mdi-text-box-search',
            name: 'third',
            title: 'Review and Certification',
            subtitle: 'Step Three',
            component: StepThree,
            completed: false
        },
        // {
        //     icon: 'mdi-credit-card',
        //     name: 'second',
        //     title: 'Pay and Submit',
        //     subtitle: 'Subtitle sample',
        //     component: StepFour,
        //     completed: false
        // }
    ],
    items: [
        { text: 'a valid passport that you will use to travel to New Zealand', icon: 'mdi-passport' },
        { text: 'a credit card or debit card (Visa or Mastercard)', icon: 'mdi-credit-card' },
        { text: 'an email address', icon: 'mdi-at' },
        { text: 'a photo of your face, or a device that can take one.', icon: 'mdi-face-recognition' },
      ],
    answers: [
        {
            "id": 1,
            "description": "No",
            "created_at": "2024-04-24T10:21:33.897Z",
            "updated_at": "2024-04-24T10:22:05.694Z"
        },
        {
            "id": 2,
            "description": "Yes",
            "created_at": "2024-04-24T10:21:33.897Z",
            "updated_at": "2024-04-24T10:22:05.695Z"
        }
    ],
    countries: [
    {
        "id": 1,
        "description": "Afghanistan",
        "iso_two": "AF",
        "iso_three": "AFG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.759Z",
        "updated_at": null
    },
    {
        "id": 2,
        "description": "Albania",
        "iso_two": "AL",
        "iso_three": "ALB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.766Z",
        "updated_at": null
    },
    {
        "id": 3,
        "description": "Algeria",
        "iso_two": "DZ",
        "iso_three": "DZA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.771Z",
        "updated_at": null
    },
    {
        "id": 4,
        "description": "Andorra",
        "iso_two": "AD",
        "iso_three": "AND",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.775Z",
        "updated_at": null
    },
    {
        "id": 5,
        "description": "Angola",
        "iso_two": "AO",
        "iso_three": "AGO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.779Z",
        "updated_at": null
    },
    {
        "id": 6,
        "description": "Antigua and Barbuda",
        "iso_two": "AG",
        "iso_three": "ATG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.783Z",
        "updated_at": null
    },
    {
        "id": 7,
        "description": "Argentina",
        "iso_two": "AR",
        "iso_three": "ARG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.787Z",
        "updated_at": null
    },
    {
        "id": 8,
        "description": "Armenia",
        "iso_two": "AM",
        "iso_three": "ARM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.792Z",
        "updated_at": null
    },
    {
        "id": 9,
        "description": "Australia",
        "iso_two": "AU",
        "iso_three": "AUS",
        "is_required": 0,
        "created_at": "2024-04-24T13:14:56.796Z",
        "updated_at": null
    },
    {
        "id": 10,
        "description": "Austria",
        "iso_two": "AT",
        "iso_three": "AUT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.800Z",
        "updated_at": null
    },
    {
        "id": 11,
        "description": "Azerbaijan",
        "iso_two": "AZ",
        "iso_three": "AZE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.804Z",
        "updated_at": null
    },
    {
        "id": 12,
        "description": "Bahamas",
        "iso_two": "BS",
        "iso_three": "BHS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.808Z",
        "updated_at": null
    },
    {
        "id": 13,
        "description": "Bahrain",
        "iso_two": "BH",
        "iso_three": "BHR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.813Z",
        "updated_at": null
    },
    {
        "id": 14,
        "description": "Bangladesh",
        "iso_two": "BD",
        "iso_three": "BGD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.817Z",
        "updated_at": null
    },
    {
        "id": 15,
        "description": "Barbados",
        "iso_two": "BB",
        "iso_three": "BRB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.821Z",
        "updated_at": null
    },
    {
        "id": 16,
        "description": "Belarus",
        "iso_two": "BY",
        "iso_three": "BLR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.824Z",
        "updated_at": null
    },
    {
        "id": 17,
        "description": "Belgium",
        "iso_two": "BE",
        "iso_three": "BEL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.828Z",
        "updated_at": null
    },
    {
        "id": 18,
        "description": "Belize",
        "iso_two": "BZ",
        "iso_three": "BLZ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.832Z",
        "updated_at": null
    },
    {
        "id": 19,
        "description": "Benin",
        "iso_two": "BJ",
        "iso_three": "BEN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.836Z",
        "updated_at": null
    },
    {
        "id": 20,
        "description": "Bhutan",
        "iso_two": "BT",
        "iso_three": "BTN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.840Z",
        "updated_at": null
    },
    {
        "id": 21,
        "description": "Bosnia and Herzegovina",
        "iso_two": "BA",
        "iso_three": "BIH",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.882Z",
        "updated_at": null
    },
    {
        "id": 22,
        "description": "Botswana",
        "iso_two": "BW",
        "iso_three": "BWA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.887Z",
        "updated_at": null
    },
    {
        "id": 23,
        "description": "Brazil",
        "iso_two": "BR",
        "iso_three": "BRA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.891Z",
        "updated_at": null
    },
    {
        "id": 24,
        "description": "British National (Overseas)",
        "iso_two": "5",
        "iso_three": "GBN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.895Z",
        "updated_at": null
    },
    {
        "id": 25,
        "description": "British Overseas citizen",
        "iso_two": "9",
        "iso_three": "GBO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.899Z",
        "updated_at": null
    },
    {
        "id": 26,
        "description": "British protected person",
        "iso_two": "8",
        "iso_three": "GBP",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.906Z",
        "updated_at": null
    },
    {
        "id": 27,
        "description": "British subject",
        "iso_two": "11",
        "iso_three": "GBS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.910Z",
        "updated_at": null
    },
    {
        "id": 28,
        "description": "Brunei Darussalam",
        "iso_two": "BN",
        "iso_three": "BRN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.914Z",
        "updated_at": null
    },
    {
        "id": 29,
        "description": "Bulgaria",
        "iso_two": "BG",
        "iso_three": "BGR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.918Z",
        "updated_at": null
    },
    {
        "id": 30,
        "description": "Burkina Faso",
        "iso_two": "BF",
        "iso_three": "BFA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.922Z",
        "updated_at": null
    },
    {
        "id": 31,
        "description": "Burundi",
        "iso_two": "BI",
        "iso_three": "BDI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.925Z",
        "updated_at": null
    },
    {
        "id": 32,
        "description": "Cabo Verde",
        "iso_two": "CV",
        "iso_three": "CPV",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.928Z",
        "updated_at": null
    },
    {
        "id": 33,
        "description": "Cambodia",
        "iso_two": "KH",
        "iso_three": "KHM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.931Z",
        "updated_at": null
    },
    {
        "id": 34,
        "description": "Cameroon",
        "iso_two": "CM",
        "iso_three": "CMR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.934Z",
        "updated_at": null
    },
    {
        "id": 35,
        "description": "Canada",
        "iso_two": "CA",
        "iso_three": "CAN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.937Z",
        "updated_at": null
    },
    {
        "id": 36,
        "description": "Central African Republic",
        "iso_two": "CF",
        "iso_three": "CAF",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.940Z",
        "updated_at": null
    },
    {
        "id": 37,
        "description": "Chad",
        "iso_two": "TD",
        "iso_three": "TCD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.943Z",
        "updated_at": null
    },
    {
        "id": 38,
        "description": "Chile",
        "iso_two": "CL",
        "iso_three": "CHL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.947Z",
        "updated_at": null
    },
    {
        "id": 39,
        "description": "China",
        "iso_two": "CN",
        "iso_three": "CHN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.951Z",
        "updated_at": null
    },
    {
        "id": 40,
        "description": "Colombia",
        "iso_two": "CO",
        "iso_three": "COL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.953Z",
        "updated_at": null
    },
    {
        "id": 41,
        "description": "Comoros",
        "iso_two": "KM",
        "iso_three": "COM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.956Z",
        "updated_at": null
    },
    {
        "id": 42,
        "description": "Congo",
        "iso_two": "CG",
        "iso_three": "COG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.960Z",
        "updated_at": null
    },
    {
        "id": 43,
        "description": "Congo (Democratic Republic of)",
        "iso_two": "CD",
        "iso_three": "COD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.964Z",
        "updated_at": null
    },
    {
        "id": 44,
        "description": "Costa Rica",
        "iso_two": "CR",
        "iso_three": "CRI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.967Z",
        "updated_at": null
    },
    {
        "id": 45,
        "description": "Cote d'Ivoire",
        "iso_two": "CI",
        "iso_three": "CIV",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.971Z",
        "updated_at": null
    },
    {
        "id": 46,
        "description": "Croatia",
        "iso_two": "HR",
        "iso_three": "HRV",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.974Z",
        "updated_at": null
    },
    {
        "id": 47,
        "description": "Cuba",
        "iso_two": "CU",
        "iso_three": "CUB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.996Z",
        "updated_at": null
    },
    {
        "id": 48,
        "description": "Cyprus",
        "iso_two": "CY",
        "iso_three": "CYP",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.001Z",
        "updated_at": null
    },
    {
        "id": 49,
        "description": "Czechia [Czech Republic]",
        "iso_two": "CZ",
        "iso_three": "CZE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.006Z",
        "updated_at": null
    },
    {
        "id": 50,
        "description": "Denmark",
        "iso_two": "DK",
        "iso_three": "DNK",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.009Z",
        "updated_at": null
    },
    {
        "id": 51,
        "description": "Djibouti",
        "iso_two": "DJ",
        "iso_three": "DJI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.013Z",
        "updated_at": null
    },
    {
        "id": 52,
        "description": "Dominica",
        "iso_two": "DM",
        "iso_three": "DMA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.015Z",
        "updated_at": null
    },
    {
        "id": 53,
        "description": "Dominican Republic",
        "iso_two": "DO",
        "iso_three": "DOM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.018Z",
        "updated_at": null
    },
    {
        "id": 54,
        "description": "Ecuador",
        "iso_two": "EC",
        "iso_three": "ECU",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.021Z",
        "updated_at": null
    },
    {
        "id": 55,
        "description": "Egypt",
        "iso_two": "EG",
        "iso_three": "EGY",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.025Z",
        "updated_at": null
    },
    {
        "id": 56,
        "description": "El Salvador",
        "iso_two": "SV",
        "iso_three": "SLV",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.029Z",
        "updated_at": null
    },
    {
        "id": 57,
        "description": "Equatorial Guinea",
        "iso_two": "GQ",
        "iso_three": "GNQ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.032Z",
        "updated_at": null
    },
    {
        "id": 58,
        "description": "Eritrea",
        "iso_two": "ER",
        "iso_three": "ERI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.035Z",
        "updated_at": null
    },
    {
        "id": 59,
        "description": "Estonia",
        "iso_two": "EE",
        "iso_three": "EST",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.038Z",
        "updated_at": null
    },
    {
        "id": 60,
        "description": "Eswatini (Kingdom of)",
        "iso_two": "SZ",
        "iso_three": "SWZ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.041Z",
        "updated_at": null
    },
    {
        "id": 61,
        "description": "Ethiopia",
        "iso_two": "ET",
        "iso_three": "ETH",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.044Z",
        "updated_at": null
    },
    {
        "id": 62,
        "description": "Fiji",
        "iso_two": "FJ",
        "iso_three": "FJI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.046Z",
        "updated_at": null
    },
    {
        "id": 63,
        "description": "Finland",
        "iso_two": "FI",
        "iso_three": "FIN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.048Z",
        "updated_at": null
    },
    {
        "id": 64,
        "description": "France",
        "iso_two": "FR",
        "iso_three": "FRA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.051Z",
        "updated_at": null
    },
    {
        "id": 65,
        "description": "Gabon",
        "iso_two": "GA",
        "iso_three": "GAB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.053Z",
        "updated_at": null
    },
    {
        "id": 66,
        "description": "Gambia",
        "iso_two": "GM",
        "iso_three": "GMB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.058Z",
        "updated_at": null
    },
    {
        "id": 67,
        "description": "Georgia",
        "iso_two": "GE",
        "iso_three": "GEO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.062Z",
        "updated_at": null
    },
    {
        "id": 68,
        "description": "Germany",
        "iso_two": "DE",
        "iso_three": "DEU",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.065Z",
        "updated_at": null
    },
    {
        "id": 69,
        "description": "Ghana",
        "iso_two": "GH",
        "iso_three": "GHA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.069Z",
        "updated_at": null
    },
    {
        "id": 70,
        "description": "Greece",
        "iso_two": "GR",
        "iso_three": "GRC",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.072Z",
        "updated_at": null
    },
    {
        "id": 71,
        "description": "Grenada",
        "iso_two": "GD",
        "iso_three": "GRD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.075Z",
        "updated_at": null
    },
    {
        "id": 72,
        "description": "Guatemala",
        "iso_two": "GT",
        "iso_three": "GTM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.078Z",
        "updated_at": null
    },
    {
        "id": 73,
        "description": "Guinea",
        "iso_two": "GN",
        "iso_three": "GIN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.082Z",
        "updated_at": null
    },
    {
        "id": 74,
        "description": "Guinea-Bissau",
        "iso_two": "GW",
        "iso_three": "GNB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.085Z",
        "updated_at": null
    },
    {
        "id": 75,
        "description": "Guyana",
        "iso_two": "GY",
        "iso_three": "GUY",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.088Z",
        "updated_at": null
    },
    {
        "id": 76,
        "description": "Haiti",
        "iso_two": "HT",
        "iso_three": "HTI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.091Z",
        "updated_at": null
    },
    {
        "id": 77,
        "description": "Honduras",
        "iso_two": "HN",
        "iso_three": "HND",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.095Z",
        "updated_at": null
    },
    {
        "id": 78,
        "description": "Hong Kong (SAR China)",
        "iso_two": "HK",
        "iso_three": "HKG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.098Z",
        "updated_at": null
    },
    {
        "id": 79,
        "description": "Hungary",
        "iso_two": "HU",
        "iso_three": "HUN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.101Z",
        "updated_at": null
    },
    {
        "id": 80,
        "description": "Iceland",
        "iso_two": "IS",
        "iso_three": "ISL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.125Z",
        "updated_at": null
    },
    {
        "id": 81,
        "description": "India",
        "iso_two": "IN",
        "iso_three": "IND",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.129Z",
        "updated_at": null
    },
    {
        "id": 82,
        "description": "Indonesia",
        "iso_two": "ID",
        "iso_three": "IDN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.132Z",
        "updated_at": null
    },
    {
        "id": 83,
        "description": "Iran (Islamic Republic of)",
        "iso_two": "IR",
        "iso_three": "IRN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.137Z",
        "updated_at": null
    },
    {
        "id": 84,
        "description": "Iraq",
        "iso_two": "IQ",
        "iso_three": "IRQ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.140Z",
        "updated_at": null
    },
    {
        "id": 85,
        "description": "Ireland (Republic of)",
        "iso_two": "IE",
        "iso_three": "IRL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.143Z",
        "updated_at": null
    },
    {
        "id": 86,
        "description": "Israel",
        "iso_two": "IL",
        "iso_three": "ISR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.145Z",
        "updated_at": null
    },
    {
        "id": 87,
        "description": "Italy",
        "iso_two": "IT",
        "iso_three": "ITA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.148Z",
        "updated_at": null
    },
    {
        "id": 88,
        "description": "Jamaica",
        "iso_two": "JM",
        "iso_three": "JAM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.152Z",
        "updated_at": null
    },
    {
        "id": 89,
        "description": "Japan",
        "iso_two": "JP",
        "iso_three": "JPN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.154Z",
        "updated_at": null
    },
    {
        "id": 90,
        "description": "Jordan",
        "iso_two": "JO",
        "iso_three": "JOR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.157Z",
        "updated_at": null
    },
    {
        "id": 91,
        "description": "Kazakhstan",
        "iso_two": "KZ",
        "iso_three": "KAZ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.160Z",
        "updated_at": null
    },
    {
        "id": 92,
        "description": "Kenya",
        "iso_two": "KE",
        "iso_three": "KEN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.164Z",
        "updated_at": null
    },
    {
        "id": 93,
        "description": "Kiribati",
        "iso_two": "KI",
        "iso_three": "KIR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.167Z",
        "updated_at": null
    },
    {
        "id": 94,
        "description": "Kosovo (Republic of)",
        "iso_two": "RK",
        "iso_three": "RKS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.175Z",
        "updated_at": null
    },
    {
        "id": 95,
        "description": "Kuwait",
        "iso_two": "KW",
        "iso_three": "KWT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.178Z",
        "updated_at": null
    },
    {
        "id": 96,
        "description": "Kyrgyzstan",
        "iso_two": "KG",
        "iso_three": "KGZ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.181Z",
        "updated_at": null
    },
    {
        "id": 97,
        "description": "Latvia",
        "iso_two": "LV",
        "iso_three": "LVA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.187Z",
        "updated_at": null
    },
    {
        "id": 98,
        "description": "Lebanon",
        "iso_two": "LB",
        "iso_three": "LBN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.191Z",
        "updated_at": null
    },
    {
        "id": 99,
        "description": "Lesotho",
        "iso_two": "LS",
        "iso_three": "LSO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.194Z",
        "updated_at": null
    },
    {
        "id": 100,
        "description": "Liberia",
        "iso_two": "LR",
        "iso_three": "LBR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.197Z",
        "updated_at": null
    },
    {
        "id": 101,
        "description": "Libya",
        "iso_two": "LY",
        "iso_three": "LBY",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.200Z",
        "updated_at": null
    },
    {
        "id": 102,
        "description": "Liechtenstein",
        "iso_two": "LI",
        "iso_three": "LIE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.203Z",
        "updated_at": null
    },
    {
        "id": 103,
        "description": "Lithuania",
        "iso_two": "LT",
        "iso_three": "LTU",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.206Z",
        "updated_at": null
    },
    {
        "id": 104,
        "description": "Luxembourg",
        "iso_two": "LU",
        "iso_three": "LUX",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.209Z",
        "updated_at": null
    },
    {
        "id": 105,
        "description": "Macao (SAR China)",
        "iso_two": "MO",
        "iso_three": "MAC",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.213Z",
        "updated_at": null
    },
    {
        "id": 106,
        "description": "Madagascar",
        "iso_two": "MG",
        "iso_three": "MDG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.216Z",
        "updated_at": null
    },
    {
        "id": 107,
        "description": "Malawi",
        "iso_two": "MW",
        "iso_three": "MWI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.219Z",
        "updated_at": null
    },
    {
        "id": 108,
        "description": "Malaysia",
        "iso_two": "MY",
        "iso_three": "MYS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.222Z",
        "updated_at": null
    },
    {
        "id": 109,
        "description": "Maldives",
        "iso_two": "MV",
        "iso_three": "MDV",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.225Z",
        "updated_at": null
    },
    {
        "id": 110,
        "description": "Mali",
        "iso_two": "ML",
        "iso_three": "MLI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.251Z",
        "updated_at": null
    },
    {
        "id": 111,
        "description": "Malta",
        "iso_two": "MT",
        "iso_three": "MLT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.257Z",
        "updated_at": null
    },
    {
        "id": 112,
        "description": "Marshall Islands",
        "iso_two": "MH",
        "iso_three": "MHL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.262Z",
        "updated_at": null
    },
    {
        "id": 113,
        "description": "Mauritania",
        "iso_two": "MR",
        "iso_three": "MRT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.265Z",
        "updated_at": null
    },
    {
        "id": 114,
        "description": "Mauritius",
        "iso_two": "MU",
        "iso_three": "MUS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.267Z",
        "updated_at": null
    },
    {
        "id": 115,
        "description": "Mexico",
        "iso_two": "MX",
        "iso_three": "MEX",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.270Z",
        "updated_at": null
    },
    {
        "id": 116,
        "description": "Moldova (Republic of)",
        "iso_two": "MD",
        "iso_three": "MDA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.275Z",
        "updated_at": null
    },
    {
        "id": 117,
        "description": "Monaco",
        "iso_two": "MC",
        "iso_three": "MCO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.278Z",
        "updated_at": null
    },
    {
        "id": 118,
        "description": "Mongolia",
        "iso_two": "MN",
        "iso_three": "MNG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.281Z",
        "updated_at": null
    },
    {
        "id": 119,
        "description": "Montenegro",
        "iso_two": "ME",
        "iso_three": "MNE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.284Z",
        "updated_at": null
    },
    {
        "id": 120,
        "description": "Morocco",
        "iso_two": "MA",
        "iso_three": "MAR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.287Z",
        "updated_at": null
    },
    {
        "id": 121,
        "description": "Mozambique",
        "iso_two": "MZ",
        "iso_three": "MOZ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.290Z",
        "updated_at": null
    },
    {
        "id": 122,
        "description": "Myanmar",
        "iso_two": "MM",
        "iso_three": "MMR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.293Z",
        "updated_at": null
    },
    {
        "id": 123,
        "description": "Namibia",
        "iso_two": "NA",
        "iso_three": "NAM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.296Z",
        "updated_at": null
    },
    {
        "id": 124,
        "description": "Nauru",
        "iso_two": "NR",
        "iso_three": "NRU",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.298Z",
        "updated_at": null
    },
    {
        "id": 125,
        "description": "Nepal",
        "iso_two": "NP",
        "iso_three": "NPL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.301Z",
        "updated_at": null
    },
    {
        "id": 126,
        "description": "Netherlands",
        "iso_two": "NL",
        "iso_three": "NLD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.304Z",
        "updated_at": null
    },
    {
        "id": 127,
        "description": "New Zealand",
        "iso_two": "NZ",
        "iso_three": "NZL",
        "is_required": 0,
        "created_at": "2024-04-24T13:14:57.307Z",
        "updated_at": null
    },
    {
        "id": 128,
        "description": "Nicaragua",
        "iso_two": "NI",
        "iso_three": "NIC",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.310Z",
        "updated_at": null
    },
    {
        "id": 129,
        "description": "Niger",
        "iso_two": "NE",
        "iso_three": "NER",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.313Z",
        "updated_at": null
    },
    {
        "id": 130,
        "description": "Nigeria",
        "iso_two": "NG",
        "iso_three": "NGA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.315Z",
        "updated_at": null
    },
    {
        "id": 131,
        "description": "North Macedonia (Republic of)",
        "iso_two": "MK",
        "iso_three": "MKD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.318Z",
        "updated_at": null
    },
    {
        "id": 132,
        "description": "Norway",
        "iso_two": "NO",
        "iso_three": "NOR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.321Z",
        "updated_at": null
    },
    {
        "id": 133,
        "description": "Oman",
        "iso_two": "OM",
        "iso_three": "OMN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.324Z",
        "updated_at": null
    },
    {
        "id": 134,
        "description": "Pakistan",
        "iso_two": "PK",
        "iso_three": "PAK",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.326Z",
        "updated_at": null
    },
    {
        "id": 135,
        "description": "Palau",
        "iso_two": "PW",
        "iso_three": "PLW",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.329Z",
        "updated_at": null
    },
    {
        "id": 136,
        "description": "Palestine",
        "iso_two": "PS",
        "iso_three": "PSE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.332Z",
        "updated_at": null
    },
    {
        "id": 137,
        "description": "Panama",
        "iso_two": "PA",
        "iso_three": "PAN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.334Z",
        "updated_at": null
    },
    {
        "id": 138,
        "description": "Papua New Guinea",
        "iso_two": "PG",
        "iso_three": "PNG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.337Z",
        "updated_at": null
    },
    {
        "id": 139,
        "description": "Paraguay",
        "iso_two": "PY",
        "iso_three": "PRY",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.341Z",
        "updated_at": null
    },
    {
        "id": 140,
        "description": "Peru",
        "iso_two": "PE",
        "iso_three": "PER",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.344Z",
        "updated_at": null
    },
    {
        "id": 141,
        "description": "Philippines",
        "iso_two": "PH",
        "iso_three": "PHL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.348Z",
        "updated_at": null
    },
    {
        "id": 142,
        "description": "Poland",
        "iso_two": "PL",
        "iso_three": "POL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.351Z",
        "updated_at": null
    },
    {
        "id": 143,
        "description": "Portugal",
        "iso_two": "PT",
        "iso_three": "PRT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.374Z",
        "updated_at": null
    },
    {
        "id": 144,
        "description": "Qatar",
        "iso_two": "QA",
        "iso_three": "QAT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.376Z",
        "updated_at": null
    },
    {
        "id": 145,
        "description": "Romania",
        "iso_two": "RO",
        "iso_three": "ROU",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.379Z",
        "updated_at": null
    },
    {
        "id": 146,
        "description": "Russian Federation",
        "iso_two": "RU",
        "iso_three": "RUS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.382Z",
        "updated_at": null
    },
    {
        "id": 147,
        "description": "Rwanda",
        "iso_two": "RW",
        "iso_three": "RWA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.384Z",
        "updated_at": null
    },
    {
        "id": 148,
        "description": "Saint Kitts and Nevis",
        "iso_two": "KN",
        "iso_three": "KNA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.388Z",
        "updated_at": null
    },
    {
        "id": 149,
        "description": "Saint Lucia",
        "iso_two": "LC",
        "iso_three": "LCA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.390Z",
        "updated_at": null
    },
    {
        "id": 150,
        "description": "Samoa",
        "iso_two": "WS",
        "iso_three": "WSM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.396Z",
        "updated_at": null
    },
    {
        "id": 151,
        "description": "San Marino",
        "iso_two": "SM",
        "iso_three": "SMR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.398Z",
        "updated_at": null
    },
    {
        "id": 152,
        "description": "Sao Tome and Principe",
        "iso_two": "ST",
        "iso_three": "STP",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.402Z",
        "updated_at": null
    },
    {
        "id": 153,
        "description": "Saudi Arabia",
        "iso_two": "SA",
        "iso_three": "SAU",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.404Z",
        "updated_at": null
    },
    {
        "id": 154,
        "description": "Senegal",
        "iso_two": "SN",
        "iso_three": "SEN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.407Z",
        "updated_at": null
    },
    {
        "id": 155,
        "description": "Serbia",
        "iso_two": "RS",
        "iso_three": "SRB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.410Z",
        "updated_at": null
    },
    {
        "id": 156,
        "description": "Seychelles",
        "iso_two": "SC",
        "iso_three": "SYC",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.412Z",
        "updated_at": null
    },
    {
        "id": 157,
        "description": "Sierra Leone",
        "iso_two": "SL",
        "iso_three": "SLE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.415Z",
        "updated_at": null
    },
    {
        "id": 158,
        "description": "Singapore",
        "iso_two": "SG",
        "iso_three": "SGP",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.417Z",
        "updated_at": null
    },
    {
        "id": 159,
        "description": "Slovakia",
        "iso_two": "SK",
        "iso_three": "SVK",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.420Z",
        "updated_at": null
    },
    {
        "id": 160,
        "description": "Slovenia",
        "iso_two": "SI",
        "iso_three": "SVN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.423Z",
        "updated_at": null
    },
    {
        "id": 161,
        "description": "Solomon Islands",
        "iso_two": "SB",
        "iso_three": "SLB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.426Z",
        "updated_at": null
    },
    {
        "id": 162,
        "description": "Somalia",
        "iso_two": "SO",
        "iso_three": "SOM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.428Z",
        "updated_at": null
    },
    {
        "id": 163,
        "description": "South Africa",
        "iso_two": "ZA",
        "iso_three": "ZAF",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.431Z",
        "updated_at": null
    },
    {
        "id": 164,
        "description": "South Sudan",
        "iso_two": "SS",
        "iso_three": "SSD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.434Z",
        "updated_at": null
    },
    {
        "id": 165,
        "description": "Spain",
        "iso_two": "ES",
        "iso_three": "ESP",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.436Z",
        "updated_at": null
    },
    {
        "id": 166,
        "description": "Sri Lanka",
        "iso_two": "LK",
        "iso_three": "LKA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.439Z",
        "updated_at": null
    },
    {
        "id": 167,
        "description": "Sudan",
        "iso_two": "SD",
        "iso_three": "SDN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.442Z",
        "updated_at": null
    },
    {
        "id": 168,
        "description": "Suriname",
        "iso_two": "SR",
        "iso_three": "SUR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.444Z",
        "updated_at": null
    },
    {
        "id": 169,
        "description": "Sweden",
        "iso_two": "SE",
        "iso_three": "SWE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.447Z",
        "updated_at": null
    },
    {
        "id": 170,
        "description": "Switzerland",
        "iso_two": "CH",
        "iso_three": "CHE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.450Z",
        "updated_at": null
    },
    {
        "id": 171,
        "description": "Syrian Arab Republic",
        "iso_two": "SY",
        "iso_three": "SYR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.452Z",
        "updated_at": null
    },
    {
        "id": 172,
        "description": "Taiwan",
        "iso_two": "TW",
        "iso_three": "TWN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.455Z",
        "updated_at": null
    },
    {
        "id": 173,
        "description": "Tajikistan",
        "iso_two": "TJ",
        "iso_three": "TJK",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.458Z",
        "updated_at": null
    },
    {
        "id": 174,
        "description": "Tanzania (United Republic of)",
        "iso_two": "TZ",
        "iso_three": "TZA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.460Z",
        "updated_at": null
    },
    {
        "id": 175,
        "description": "Thailand",
        "iso_two": "TH",
        "iso_three": "THA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.463Z",
        "updated_at": null
    },
    {
        "id": 176,
        "description": "Timor-Leste",
        "iso_two": "TL",
        "iso_three": "TLS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.490Z",
        "updated_at": null
    },
    {
        "id": 177,
        "description": "Togo",
        "iso_two": "TG",
        "iso_three": "TGO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.493Z",
        "updated_at": null
    },
    {
        "id": 178,
        "description": "Tonga",
        "iso_two": "TO",
        "iso_three": "TON",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.498Z",
        "updated_at": null
    },
    {
        "id": 179,
        "description": "Trinidad and Tobago",
        "iso_two": "TT",
        "iso_three": "TTO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.503Z",
        "updated_at": null
    },
    {
        "id": 180,
        "description": "Tunisia",
        "iso_two": "TN",
        "iso_three": "TUN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.507Z",
        "updated_at": null
    },
    {
        "id": 181,
        "description": "Turkey",
        "iso_two": "TR",
        "iso_three": "TUR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.511Z",
        "updated_at": null
    },
    {
        "id": 182,
        "description": "Turkmenistan",
        "iso_two": "TM",
        "iso_three": "TKM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.514Z",
        "updated_at": null
    },
    {
        "id": 183,
        "description": "Tuvalu",
        "iso_two": "TV",
        "iso_three": "TUV",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.517Z",
        "updated_at": null
    },
    {
        "id": 184,
        "description": "Uganda",
        "iso_two": "UG",
        "iso_three": "UGA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.520Z",
        "updated_at": null
    },
    {
        "id": 185,
        "description": "Ukraine",
        "iso_two": "UA",
        "iso_three": "UKR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.522Z",
        "updated_at": null
    },
    {
        "id": 186,
        "description": "United Arab Emirates",
        "iso_two": "AE",
        "iso_three": "ARE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.525Z",
        "updated_at": null
    },
    {
        "id": 187,
        "description": "United Nations Organisation",
        "iso_two": "UN",
        "iso_three": "UNO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.530Z",
        "updated_at": null
    },
    {
        "id": 188,
        "description": "United States of America",
        "iso_two": "US",
        "iso_three": "USA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.533Z",
        "updated_at": null
    },
    {
        "id": 189,
        "description": "Uruguay",
        "iso_two": "UY",
        "iso_three": "URY",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.536Z",
        "updated_at": null
    },
    {
        "id": 190,
        "description": "Uzbekistan",
        "iso_two": "UZ",
        "iso_three": "UZB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.539Z",
        "updated_at": null
    },
    {
        "id": 191,
        "description": "Vanuatu",
        "iso_two": "VU",
        "iso_three": "VUT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.541Z",
        "updated_at": null
    },
    {
        "id": 192,
        "description": "Vatican City State [Holy See]",
        "iso_two": "VA",
        "iso_three": "VAT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.544Z",
        "updated_at": null
    },
    {
        "id": 193,
        "description": "Viet Nam",
        "iso_two": "VN",
        "iso_three": "VNM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.549Z",
        "updated_at": null
    },
    {
        "id": 194,
        "description": "Yemen",
        "iso_two": "YE",
        "iso_three": "YEM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.552Z",
        "updated_at": null
    },
    {
        "id": 195,
        "description": "Zambia",
        "iso_two": "ZM",
        "iso_three": "ZMB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.555Z",
        "updated_at": null
    },
    {
        "id": 196,
        "description": "Zimbabwe",
        "iso_two": "ZW",
        "iso_three": "ZWE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.557Z",
        "updated_at": null
    }
    ],
    genders: [
        {
            "id": 1,
            "description": "Female",
            "created_at": "2024-04-25T03:49:23.742Z",
            "updated_at": null
        },
        {
            "id": 2,
            "description": "Male",
            "created_at": "2024-04-25T03:49:23.743Z",
            "updated_at": null
        },
        {
            "id": 3,
            "description": "Gender Diverse",
            "created_at": "2024-04-25T03:49:23.743Z",
            "updated_at": null
        }
    ],
    showButtons: true,
    showResult: false,
    formdata_final: {},
    isSubmitting: false,
    cloudflare_ping: {},
    render: false,
  }),
  methods: {
      completeStep(payload) {
          this.demoSteps.forEach((step, index) => {
              if (step.name === payload.name) {
                if(step.name == 'second'){
                    this.uploadPhotoAndSave(index);
                }
                else{
                    this.saveApplication(index + 1, false);
                }
              }
          })
      },
      isStepActive(payload) {
          this.demoSteps.forEach((step) => {
              if (step.name === payload.name) {
                  if(step.completed === true) {
                      step.completed = false;
                  }
              }
          })
      },
      finish(payload) {
          console.log("Finished", payload)
          this.formdata_final = this.finalizeData();
        //   this.showResult = true;
          this.confirmApplication();
      },
      finalizeData(){
        let language = this.$store.state.language;
          let data = this.$store.state.formdata;

          let passport = {};
          if(data.passport){
            passport = {
                nationality: this.displayCountryCode(data.passport.nationality),
                nationality_text: this.displayCountry(data.passport.nationality),
                passport_territory: this.displayCountryCode(data.passport.territory),
                passport_territory_text: this.displayCountry(data.passport.territory),
                passport_number: data.passport.number,
                passport_expiry: data.passport.expiry_date,
            }
          }

          let residency = [];
          if(data.residency_questions){
          data.residency_questions.forEach(item => {
            residency.push({
              question: item.question_min,
              question_text: item.question,
              answer: this.displayAnswer(item.choice || null),
            })
            if(item.follow_up){
              item.follow_up.forEach(item2 => {
                if(item2.choice){
                residency.push({
                  question: item2.question_min,
                  question_text: item2.question,
                  answer: this.displayAnswer(item2.choice || null),
                })
                }

                if(item2.follow_up){
                  item2.follow_up.forEach(item3 => {
                    if(item3.choice){
                    residency.push({
                      question: item3.question_min,
                      question_text: item3.question,
                      answer: this.displayAnswer(item3.choice || null),
                    })
                    }
                  });
                }
              });
            }
          });
          }

          let eligibility = [];
          if(data.eligibility_questions){
          data.eligibility_questions.forEach(item => {
            eligibility.push({
              question: item.question_min,
              question_text: item.question,
              answer: this.displayAnswer(item.choice || null),
            })
            if(item.follow_up){
              item.follow_up.forEach(item2 => {
                if(item2.choice){
                eligibility.push({
                  question: item2.question_min,
                  question_text: item2.question,
                  answer: this.displayAnswer(item2.choice || null),
                })
                }

                if(item2.follow_up){
                  item2.follow_up.forEach(item3 => {
                    if(item3.choice){
                    eligibility.push({
                      question: item3.question_min,
                      question_text: item3.question,
                      answer: this.displayAnswer(item3.choice || null),
                    })
                    }
                  });
                }
              });
            }
          });
          }
          let traveller = {};
          if(data.traveller){
          traveller = {
            family_names: data.traveller.lastname,
            first_given_names: data.traveller.firstname,
            more_name: this.displayAnswer(data.traveller.more_names || null),
            other_names: data.traveller.other_names,
            gender: this.displayGender(data.traveller.gender_id || null),
            birthdate: data.traveller.birthdate,
            birthplace: data.traveller.birthplace,
            country: this.displayCountryCode(data.traveller.country_id || null),
            country_text: this.displayCountry(data.traveller.country_id || null),
            national_id: data.traveller.national_id,
            email: data.traveller.email,
            photo: data.traveller.photo_url,
          }
          }
          let certification = {};
          if(data.certification){
            certification = {
                declaration: data.declaration,
                terms: data.terms,
            }
          }
          let final_data = {
            token: this.$store.state.token,
            type: "NZ-ETA",
            lang: language && language != 'auto' ? language : 'en',
            aff_id: "",
            aff_cid: "",
            media_property: "e-newzealand",
            ...passport,
            residency_question: residency,
            eligibility_question: eligibility,
            ...traveller,
            ...certification,
          }
          return final_data;
      },
      confirmApplication(){
        this.isSubmitting = true;
        this.saveApplication(3, true);
      },
      displayAnswer(id){
        return id != null ? this.answers.find((answer) => answer.id == id).description : '';
      },
      displayCountryCode(id){
        return id != null ? this.countries.find((country) => country.id == id).iso_two : '';
      },
      displayCountry(id){
        return id != null ? this.countries.find((country) => country.id == id).description : '';
      },
      displayGender(id){
        return id != null ? this.genders.find((gender) => gender.id == id).description : '';
      },
      saveApplication(step, completed){
        let lcs = this.$store.state.formdata.last_completed_step || 0;
        let other_data = {
            signed_application_bool: completed == 1 ? true : false,
            signed_application_timestamp: new Date(Date.now()),
            signed_application_ip: this.cloudflare_ping.ip,
            signed_application_uag: this.cloudflare_ping.uag,
            last_completed_step: step > lcs ? step : lcs,
            completed: completed
        }
        let formdata = this.finalizeData();
        formdata = {...formdata, ...other_data}
        this.axiosCall(this.apiUrl2 + "/nz-eta/submit/", "POST", formdata)
            .then((res) => {
                if (res.data) {
                    if(completed){
                        this.isSubmitting = false;
                        this.showResult = false;
                        // this.$router.push('/')
                        // this.$router.go(0);
                        // window.open("https://ketoniks-eta.com/payment2/" + this.$store.state.token + "/", "_self")
                        this.$router.push("/application/" + this.$store.state.token + "/payment/", () => {});
                    }
                    this.demoSteps[step - 1].completed = true;
                    if(step == 1){
                      window._gs('event', 'Passport and Eligibility');
                    }
                    else if(step == 2){
                      window._gs('event', 'Traveller Details');
                      window._gs('identify', {
                          id: formdata.token,
                          first_name: formdata.first_given_names,
                          last_name: formdata.family_names,
                          email: formdata.email,
                          username: formdata.token
                      });
                    }
                    else if(step == 3){
                      window._gs('event', 'Review and Certification');
                    }
                }
            })
            .catch((error) => {
                this.isSubmitting = false;
                console.log(error);
            });
      },
      uploadPhotoAndSave(index){
        let photo = this.$store.state.formdata.traveller.photo;
        let photo_uploaded = this.$store.state.formdata.traveller.photo_url;
        let api_length = this.apiUrl1.length;
        if(photo){
        let formdata = new FormData();
        formdata.append('file', photo);
        formdata.append('file_delete', photo_uploaded ? photo_uploaded.substring(api_length + 1, photo_uploaded.length) : '');
        this.axiosCall(this.apiUrl1 + "/upload.php", "POST", formdata, {'Content-Type' : 'multipart/form-data'})
        .then((res) => {
            if(res.data.image != ''){
                let data = this.$store.state.formdata;
                data.traveller.photo_url = this.apiUrl1 + '/' + res.data.image;
                this.$store.dispatch("setFormdata", data);
                this.saveApplication(index + 1, false);
            }
            else{
                console.log(res.data.message)
            }
        }).catch((error) => {
            console.log(error);
        });
        }
        else{
            this.saveApplication(index + 1, false);
        }
    },
    getCloudflare(){
    this.axiosCall(this.apiUrl1 + "/cdn-cgi/trace", "GET")
          .then((res) => {
                let data = res.data.trim().split('\n').reduce(function(obj, pair) {
                pair = pair.split('=');
                return obj[pair[0]] = pair[1], obj;
                }, {});
                this.cloudflare_ping = data;
          })
          .catch((error) => {
            console.log(error);
          });
  },
  },
  mounted() {
    this.render = true;
    this.getCloudflare();
    if(this.$store.state.formdata){
    let step = this.$store.state.formdata.last_completed_step || 0
    if(step < 3){
    let formdata = this.$store.state.formdata;
    let token = this.$store.state.token;
    this.demoSteps.forEach((item, index) => {
        if(index < parseInt(step)){
            item.completed = true;
            if(step == 1){
                window._gs('event', 'Passport and Eligibility');
            }
            else if(step == 2){
                window._gs('event', 'Traveller Details');
                window._gs('identify', {
                    id: token,
                    first_name: formdata.traveller.firstname,
                    last_name: formdata.traveller.lastname,
                    email: formdata.traveller.email,
                    username: token
                });
            }
            else if(step == 3){
                window._gs('event', 'Review and Certification');
            }
        }
    });
    }
    }
  },
};
</script>

<style scoped>
.outer-content {
  background-color: #f2f2f2 !important;
}
.bg-content {
  /* background: url("../../assets/img/background.png"); */
   position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.inner-content{
    background-color: #f2f2f2 !important;
    padding: 0 13%;
}
.content{
    margin: 0 15%;
}
.title{
    margin: 0 20%;
    margin-top: 80px;
}
.desc {
    margin-top: 30px;
}
.requirements {
  margin-top: 20px;
}
.req-list{
  background-color: transparent !important;
}
@media only screen and (max-width: 600px) {
  .title{
    margin: 0;
    margin-top: 60px;
  }
  .content{
    margin: 0;
  }
  .requirements {
    margin: 0;
    margin-top: 20px;
  }
  .inner-content{
    padding: 0 3%;
    }
}
</style>
