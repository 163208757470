<template>
  <div>
    <div class="outer-content">
    <div class="bg-content"></div>
      <div class="inner-content">
       <v-toolbar elevation="0" class="pa-2" color="transparent" max-height="45">
        <v-img src="../../assets/img/nzeta-logo.png" max-width="140"></v-img>
        <v-spacer></v-spacer>
      <!-- <v-chip @click="showTranslator" outlined color="#dc2a5f"><span class="notranslate">{{cur_language}}</span></v-chip> -->
        <Translation />
       </v-toolbar>
    </div>
  </div>
  <div class="content">
        <section class="section">
    <div class="container">
        <div class="columns">
            <div class="column is-8 is-offset-2 text-center">
                <v-img class="mx-auto" width="150" height="150" src="../../assets/img/success.svg"></v-img>
                <h1 class="mb-5">Thank you for your payment!</h1>
                <p class="mb-8">Thank you for completing your NZ-ETA (New Zealand Electronic Travel Authorization) application. Your application will be submitted to New Zealand Customs and Border Protection within the next 3 hours. You'll receive a separate email confirming the submission of your application with further information on how you can check its status online.</p>
                <p class="mb-8">Wish to start another application for a close friend or a relative?</p>
                <v-btn color="#dc2a5f" dark class="elevation-0 pa-5 rounded-sm mb-3" @click="startNewApplication()">Start Another New Application</v-btn>
            </div>
        </div>
    </div>
</section>
      </div>
  </div>
</template>

<script>
import Translation from '../../components/Utils/Translation.vue';
export default {
  name: "PaymentThankYou",
  components: {
    Translation,
  },
  data: () => ({
    
  }),
  methods: {
    startNewApplication(){
      this.$router.push("/");
      this.$router.go();
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.outer-content {
  background-color: #f2f2f2 !important;
}
.bg-content {
  /* background: url("../../assets/img/background.png"); */
   position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.inner-content{
    background-color: #f2f2f2 !important;
    padding: 0 13%;
}
.content{
    margin: 80px 15%;
}
.columns{
    background: white;
    border-radius: 10px;
    padding: 30px;
}
@media only screen and (max-width: 600px) {
    .inner-content{
        padding: 0 3%;
    }
    .content{
        margin: 80px 0;
    }
}
</style>
