<template>
<div class="pa-10 div-container">
    <v-form @submit.prevent ref="formRef">
  <v-row no-gutters>
    <v-col cols="12" class="pb-0"><div class="text-h5 text-sm-h4">Passport Details</div></v-col>
    <v-col cols="12"><v-divider></v-divider></v-col>
    <v-col cols="12" class="pb-0">
        <v-subheader class="px-0">Select your nationality as shown on the passport you will be travelling on <span class="asterisk">*</span></v-subheader>
        <v-autocomplete
        v-model="formdata.passport.nationality"
        color="#dc2a5f"
        :rules="[formRules.required, formRules.restrict(restricted)]"
        dense
        outlined
        item-text="description"
        item-value="id"
        :items="countries"
        item-color="#dc2a5f"
        @change="checkNationality()"
        >
        </v-autocomplete>
    </v-col>
    <v-col v-if="restricted && formdata.passport.nationality != null">
        <v-alert
        shaped
        prominent
        type="error"
        color="#e34f26"
        >
        You do not need an NZeTA if you are travelling on a New Zealand or Australian passport.
        </v-alert>
    </v-col>
  </v-row>
  <div v-show="!restricted && formdata.passport.nationality != null">
  <v-row no-gutters>
    <v-col cols="12">
        <v-subheader class="px-0">Select the issuing country or territory as shown on your passport <span class="asterisk">*</span></v-subheader>
        <v-autocomplete
            v-model="formdata.passport.territory"
            color="#dc2a5f"
            :rules="[formRules.required]"
            dense
            outlined
            item-text="description"
            item-value="id"
            :items="countries"
            item-color="#dc2a5f"
        ></v-autocomplete>
    </v-col>
    <v-col cols="12">
        <v-subheader class="px-0">Enter your passport number exactly as it appears on your passport <span class="asterisk">*</span></v-subheader>
        <v-text-field
        v-model="formdata.passport.number"
        color="#dc2a5f"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        dense
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
        <v-subheader class="px-0">Select the expiry date as shown on your passport <span class="asterisk">*</span></v-subheader>
        <!-- <div class="mb-6">Active picker: <code>{{ activePicker || 'null' }}</code></div> -->
        <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="auto"
        >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-model="formdata.passport.expiry_date"
            :rules="[formRules.required, formRules.passportExpiry]"
            readonly
            v-bind="attrs"
            outlined
            color="#dc2a5f"
            dense
            v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="formdata.passport.expiry_date"
            :active-picker.sync="activePicker1"
            :min="getValidDates()"
            color="#dc2a5f"
            @change="save"
        ></v-date-picker>
        </v-menu>
    </v-col>
    <v-col cols="12" v-if="formdata.passport.territory && formdata.passport.number && formdata.passport.expiry_date"><v-divider></v-divider></v-col>
    <v-col cols="12" v-if="formdata.passport.territory && formdata.passport.number && formdata.passport.expiry_date">
        <v-row no-gutters v-for="question in formdata.residency_questions" :key="'r_question_' + question.id">
            <v-col cols="12">
                <v-subheader class="px-0">{{question.question}} <span v-if="question.is_required == 1" class="asterisk">*</span></v-subheader>
                <v-radio-group
                    v-model="question.choice"
                    @change="updateVariable()"
                    :rules="[formRules.required2, formRules.checkAnswer(question.choice, question.answer, question.follow_up.length)]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        v-for="answer in answers" :key="answer.id"
                        :label="answer.description"
                        :value="answer.id"
                        color="#dc2a5f"
                    ></v-radio>
                    </v-radio-group>
                <div v-for="am in question.alertMessage" :key="'am' + am.id">
                <v-alert
                    shaped
                    prominent
                    type="error"
                    color="#e34f26"
                    v-if="am.question_id == question.id && am.answer_id == question.choice"
                >
                {{am.message}}
                </v-alert>
                </div>
            </v-col>
            <v-col cols="12" v-if="question.follow_up.length > 0 && question.choice != null && question.answer != question.choice">
            <v-row no-gutters v-for="follow_up1 in question.follow_up" :key="'r_follow_up_1_' + follow_up1.id">
            <v-col cols="12">
                <v-subheader class="px-0">{{follow_up1.question}} <span v-if="follow_up1.is_required == 1" class="asterisk">*</span></v-subheader>
                <v-radio-group
                    v-model="follow_up1.choice"
                    @change="updateVariable()"
                    :rules="[formRules.required2, formRules.checkAnswer(follow_up1.choice, follow_up1.answer, follow_up1.follow_up.length)]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        v-for="answer in answers" :key="answer.id"
                        :label="answer.description"
                        :value="answer.id"
                        color="#dc2a5f"
                    ></v-radio>
                    </v-radio-group>
                <div v-for="f1_am in follow_up1.alertMessage" :key="'f1_am' + f1_am.id">
                <v-alert
                    shaped
                    prominent
                    type="error"
                    color="#e34f26"
                    v-if="f1_am.question_id == follow_up1.id && f1_am.answer_id == follow_up1.choice"
                >
                {{f1_am.message}}
                </v-alert>
                </div>
            </v-col>
            <v-col cols="12" v-if="follow_up1.follow_up.length > 0 && follow_up1.choice != null && follow_up1.answer != follow_up1.choice">
            <v-row no-gutters v-for="follow_up2 in follow_up1.follow_up" :key="'r_follow_up_2_' + follow_up2.id">
            <v-col cols="12">
                <v-subheader class="px-0">{{follow_up2.question}} <span v-if="follow_up2.is_required == 1" class="asterisk">*</span></v-subheader>
                <v-radio-group
                    v-model="follow_up2.choice"
                    @change="updateVariable()"
                    :rules="[formRules.required2, formRules.checkAnswer(follow_up2.choice, follow_up2.answer, follow_up2.follow_up.length)]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        v-for="answer in answers" :key="answer.id"
                        :label="answer.description"
                        :value="answer.id"
                        color="#dc2a5f"
                    ></v-radio>
                    </v-radio-group>
                <div v-for="f2_am in follow_up2.alertMessage" :key="'f2_am' + f2_am.id">
                <v-alert
                    shaped
                    prominent
                    type="error"
                    color="#e34f26"
                    v-if="f2_am.question_id == follow_up2.id && f2_am.answer_id == follow_up2.choice"
                >
                {{f2_am.message}}
                </v-alert>
                </div>
            </v-col>
            </v-row>
            </v-col>

            </v-row>
            </v-col>
        </v-row>
    </v-col>
  </v-row>
  <v-row no-gutters  v-show="eligible">
    <v-col cols="12"><v-divider></v-divider></v-col>
    <v-col cols="12"><div class="text-h5 text-sm-h4 my-4">Eligibility Questions</div></v-col>
    <v-col cols="12"><v-divider></v-divider></v-col>
    <v-col cols="12">
        <v-row no-gutters v-for="question in formdata.eligibility_questions" :key="'e_question_' + question.id">
            <v-col cols="12">
                <v-subheader class="px-0">{{question.question}} <span v-if="question.is_required == 1" class="asterisk">*</span></v-subheader>
                <v-radio-group
                    v-model="question.choice"
                    @change="updateVariable()"
                    :rules="[formRules.required2, formRules.checkAnswer(question.choice, question.answer, question.follow_up.length)]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        v-for="answer in answers" :key="answer.id"
                        :label="answer.description"
                        :value="answer.id"
                        color="#dc2a5f"
                    ></v-radio>
                    </v-radio-group>
                <div v-for="am in question.alertMessage" :key="'am' + am.id">
                <v-alert
                    shaped
                    prominent
                    type="error"
                    color="#e34f26"
                    v-if="am.question_id == question.id && am.answer_id == question.choice"
                >
                {{am.message}}
                </v-alert>
                </div>
            </v-col>
            <v-col cols="12" v-if="question.follow_up.length > 0 && question.choice != null && question.answer != question.choice">
            <v-row no-gutters v-for="follow_up1 in question.follow_up" :key="'e_follow_up1_' + follow_up1.id">
            <v-col cols="12">
                <v-subheader class="px-0">{{follow_up1.question}} <span v-if="follow_up1.is_required == 1" class="asterisk">*</span></v-subheader>
                <v-radio-group
                    v-model="follow_up1.choice"
                    @change="updateVariable()"
                    :rules="[formRules.required2, formRules.checkAnswer(follow_up1.choice, follow_up1.answer, follow_up1.follow_up.length)]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        v-for="answer in answers" :key="answer.id"
                        :label="answer.description"
                        :value="answer.id"
                        color="#dc2a5f"
                    ></v-radio>
                    </v-radio-group>
                <div v-for="f1_am in follow_up1.alertMessage" :key="'f1_am' + f1_am.id">
                <v-alert
                    shaped
                    prominent
                    type="error"
                    color="#e34f26"
                    v-if="f1_am.question_id == follow_up1.id && f1_am.answer_id == follow_up1.choice"
                >
                {{f1_am.message}}
                </v-alert>
                </div>
            </v-col>
            <v-col cols="12" v-if="follow_up1.follow_up.length > 0 && follow_up1.choice != null && follow_up1.answer != follow_up1.choice">
            <v-row no-gutters v-for="follow_up2 in follow_up1.follow_up" :key="'e_follow_up_2_' + follow_up2.id">
            <v-col cols="12">
                <v-subheader class="px-0">{{follow_up2.question}} <span v-if="follow_up2.is_required == 1" class="asterisk">*</span></v-subheader>
                <v-radio-group
                    v-model="follow_up2.choice"
                    @change="updateVariable()"
                    :rules="[formRules.required2, formRules.checkAnswer(follow_up2.choice, follow_up2.answer, follow_up2.follow_up.length)]"
                    row
                    class="mt-0"
                    >
                    <v-radio
                        v-for="answer in answers" :key="answer.id"
                        :label="answer.description"
                        :value="answer.id"
                        color="#dc2a5f"
                    ></v-radio>
                    </v-radio-group>
                <div v-for="f2_am in follow_up2.alertMessage" :key="'f2_am' + f2_am.id">
                <v-alert
                    shaped
                    prominent
                    type="error"
                    color="#e34f26"
                    v-if="f2_am.question_id == follow_up2.id && f2_am.answer_id == follow_up2.choice"
                >
                {{f2_am.message}}
                </v-alert>
                </div>
            </v-col>
            </v-row>
            </v-col>

            </v-row>
            </v-col>
        </v-row>
    </v-col>
  </v-row>
  </div>
  <v-row class="justify-center mt-10" v-show="false">
  <v-btn @click="reset()" outlined color="grey darken-2" class="mx-1 rounded-0 elevation-0">reset</v-btn>
  <v-btn @click="confirm()" id="confirm_btn" color="grey darken-2" class="mx-1 rounded-0 elevation-0 white--text" :loading="loadingConfirm">save Details</v-btn>
  </v-row>
  </v-form>
</div>
</template>
<script>
  export default {
    props: ['clickedNext', 'currentStep'],
    data: () => ({
    formdata: {
        passport: {
            nationality: null,
            territory: null,
            number: null,
            expiry_date: null,
        },
        residency_questions: [],
        eligibility_questions: [],
    },
    countries: [
    {
        "id": 1,
        "description": "Afghanistan",
        "iso_two": "AF",
        "iso_three": "AFG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.759Z",
        "updated_at": null
    },
    {
        "id": 2,
        "description": "Albania",
        "iso_two": "AL",
        "iso_three": "ALB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.766Z",
        "updated_at": null
    },
    {
        "id": 3,
        "description": "Algeria",
        "iso_two": "DZ",
        "iso_three": "DZA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.771Z",
        "updated_at": null
    },
    {
        "id": 4,
        "description": "Andorra",
        "iso_two": "AD",
        "iso_three": "AND",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.775Z",
        "updated_at": null
    },
    {
        "id": 5,
        "description": "Angola",
        "iso_two": "AO",
        "iso_three": "AGO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.779Z",
        "updated_at": null
    },
    {
        "id": 6,
        "description": "Antigua and Barbuda",
        "iso_two": "AG",
        "iso_three": "ATG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.783Z",
        "updated_at": null
    },
    {
        "id": 7,
        "description": "Argentina",
        "iso_two": "AR",
        "iso_three": "ARG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.787Z",
        "updated_at": null
    },
    {
        "id": 8,
        "description": "Armenia",
        "iso_two": "AM",
        "iso_three": "ARM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.792Z",
        "updated_at": null
    },
    {
        "id": 9,
        "description": "Australia",
        "iso_two": "AU",
        "iso_three": "AUS",
        "is_required": 0,
        "created_at": "2024-04-24T13:14:56.796Z",
        "updated_at": null
    },
    {
        "id": 10,
        "description": "Austria",
        "iso_two": "AT",
        "iso_three": "AUT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.800Z",
        "updated_at": null
    },
    {
        "id": 11,
        "description": "Azerbaijan",
        "iso_two": "AZ",
        "iso_three": "AZE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.804Z",
        "updated_at": null
    },
    {
        "id": 12,
        "description": "Bahamas",
        "iso_two": "BS",
        "iso_three": "BHS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.808Z",
        "updated_at": null
    },
    {
        "id": 13,
        "description": "Bahrain",
        "iso_two": "BH",
        "iso_three": "BHR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.813Z",
        "updated_at": null
    },
    {
        "id": 14,
        "description": "Bangladesh",
        "iso_two": "BD",
        "iso_three": "BGD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.817Z",
        "updated_at": null
    },
    {
        "id": 15,
        "description": "Barbados",
        "iso_two": "BB",
        "iso_three": "BRB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.821Z",
        "updated_at": null
    },
    {
        "id": 16,
        "description": "Belarus",
        "iso_two": "BY",
        "iso_three": "BLR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.824Z",
        "updated_at": null
    },
    {
        "id": 17,
        "description": "Belgium",
        "iso_two": "BE",
        "iso_three": "BEL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.828Z",
        "updated_at": null
    },
    {
        "id": 18,
        "description": "Belize",
        "iso_two": "BZ",
        "iso_three": "BLZ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.832Z",
        "updated_at": null
    },
    {
        "id": 19,
        "description": "Benin",
        "iso_two": "BJ",
        "iso_three": "BEN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.836Z",
        "updated_at": null
    },
    {
        "id": 20,
        "description": "Bhutan",
        "iso_two": "BT",
        "iso_three": "BTN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.840Z",
        "updated_at": null
    },
    {
        "id": 21,
        "description": "Bosnia and Herzegovina",
        "iso_two": "BA",
        "iso_three": "BIH",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.882Z",
        "updated_at": null
    },
    {
        "id": 22,
        "description": "Botswana",
        "iso_two": "BW",
        "iso_three": "BWA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.887Z",
        "updated_at": null
    },
    {
        "id": 23,
        "description": "Brazil",
        "iso_two": "BR",
        "iso_three": "BRA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.891Z",
        "updated_at": null
    },
    {
        "id": 24,
        "description": "British National (Overseas)",
        "iso_two": "5",
        "iso_three": "GBN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.895Z",
        "updated_at": null
    },
    {
        "id": 25,
        "description": "British Overseas citizen",
        "iso_two": "9",
        "iso_three": "GBO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.899Z",
        "updated_at": null
    },
    {
        "id": 26,
        "description": "British protected person",
        "iso_two": "8",
        "iso_three": "GBP",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.906Z",
        "updated_at": null
    },
    {
        "id": 27,
        "description": "British subject",
        "iso_two": "11",
        "iso_three": "GBS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.910Z",
        "updated_at": null
    },
    {
        "id": 28,
        "description": "Brunei Darussalam",
        "iso_two": "BN",
        "iso_three": "BRN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.914Z",
        "updated_at": null
    },
    {
        "id": 29,
        "description": "Bulgaria",
        "iso_two": "BG",
        "iso_three": "BGR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.918Z",
        "updated_at": null
    },
    {
        "id": 30,
        "description": "Burkina Faso",
        "iso_two": "BF",
        "iso_three": "BFA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.922Z",
        "updated_at": null
    },
    {
        "id": 31,
        "description": "Burundi",
        "iso_two": "BI",
        "iso_three": "BDI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.925Z",
        "updated_at": null
    },
    {
        "id": 32,
        "description": "Cabo Verde",
        "iso_two": "CV",
        "iso_three": "CPV",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.928Z",
        "updated_at": null
    },
    {
        "id": 33,
        "description": "Cambodia",
        "iso_two": "KH",
        "iso_three": "KHM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.931Z",
        "updated_at": null
    },
    {
        "id": 34,
        "description": "Cameroon",
        "iso_two": "CM",
        "iso_three": "CMR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.934Z",
        "updated_at": null
    },
    {
        "id": 35,
        "description": "Canada",
        "iso_two": "CA",
        "iso_three": "CAN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.937Z",
        "updated_at": null
    },
    {
        "id": 36,
        "description": "Central African Republic",
        "iso_two": "CF",
        "iso_three": "CAF",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.940Z",
        "updated_at": null
    },
    {
        "id": 37,
        "description": "Chad",
        "iso_two": "TD",
        "iso_three": "TCD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.943Z",
        "updated_at": null
    },
    {
        "id": 38,
        "description": "Chile",
        "iso_two": "CL",
        "iso_three": "CHL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.947Z",
        "updated_at": null
    },
    {
        "id": 39,
        "description": "China",
        "iso_two": "CN",
        "iso_three": "CHN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.951Z",
        "updated_at": null
    },
    {
        "id": 40,
        "description": "Colombia",
        "iso_two": "CO",
        "iso_three": "COL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.953Z",
        "updated_at": null
    },
    {
        "id": 41,
        "description": "Comoros",
        "iso_two": "KM",
        "iso_three": "COM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.956Z",
        "updated_at": null
    },
    {
        "id": 42,
        "description": "Congo",
        "iso_two": "CG",
        "iso_three": "COG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.960Z",
        "updated_at": null
    },
    {
        "id": 43,
        "description": "Congo (Democratic Republic of)",
        "iso_two": "CD",
        "iso_three": "COD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.964Z",
        "updated_at": null
    },
    {
        "id": 44,
        "description": "Costa Rica",
        "iso_two": "CR",
        "iso_three": "CRI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.967Z",
        "updated_at": null
    },
    {
        "id": 45,
        "description": "Cote d'Ivoire",
        "iso_two": "CI",
        "iso_three": "CIV",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.971Z",
        "updated_at": null
    },
    {
        "id": 46,
        "description": "Croatia",
        "iso_two": "HR",
        "iso_three": "HRV",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.974Z",
        "updated_at": null
    },
    {
        "id": 47,
        "description": "Cuba",
        "iso_two": "CU",
        "iso_three": "CUB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:56.996Z",
        "updated_at": null
    },
    {
        "id": 48,
        "description": "Cyprus",
        "iso_two": "CY",
        "iso_three": "CYP",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.001Z",
        "updated_at": null
    },
    {
        "id": 49,
        "description": "Czechia [Czech Republic]",
        "iso_two": "CZ",
        "iso_three": "CZE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.006Z",
        "updated_at": null
    },
    {
        "id": 50,
        "description": "Denmark",
        "iso_two": "DK",
        "iso_three": "DNK",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.009Z",
        "updated_at": null
    },
    {
        "id": 51,
        "description": "Djibouti",
        "iso_two": "DJ",
        "iso_three": "DJI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.013Z",
        "updated_at": null
    },
    {
        "id": 52,
        "description": "Dominica",
        "iso_two": "DM",
        "iso_three": "DMA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.015Z",
        "updated_at": null
    },
    {
        "id": 53,
        "description": "Dominican Republic",
        "iso_two": "DO",
        "iso_three": "DOM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.018Z",
        "updated_at": null
    },
    {
        "id": 54,
        "description": "Ecuador",
        "iso_two": "EC",
        "iso_three": "ECU",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.021Z",
        "updated_at": null
    },
    {
        "id": 55,
        "description": "Egypt",
        "iso_two": "EG",
        "iso_three": "EGY",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.025Z",
        "updated_at": null
    },
    {
        "id": 56,
        "description": "El Salvador",
        "iso_two": "SV",
        "iso_three": "SLV",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.029Z",
        "updated_at": null
    },
    {
        "id": 57,
        "description": "Equatorial Guinea",
        "iso_two": "GQ",
        "iso_three": "GNQ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.032Z",
        "updated_at": null
    },
    {
        "id": 58,
        "description": "Eritrea",
        "iso_two": "ER",
        "iso_three": "ERI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.035Z",
        "updated_at": null
    },
    {
        "id": 59,
        "description": "Estonia",
        "iso_two": "EE",
        "iso_three": "EST",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.038Z",
        "updated_at": null
    },
    {
        "id": 60,
        "description": "Eswatini (Kingdom of)",
        "iso_two": "SZ",
        "iso_three": "SWZ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.041Z",
        "updated_at": null
    },
    {
        "id": 61,
        "description": "Ethiopia",
        "iso_two": "ET",
        "iso_three": "ETH",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.044Z",
        "updated_at": null
    },
    {
        "id": 62,
        "description": "Fiji",
        "iso_two": "FJ",
        "iso_three": "FJI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.046Z",
        "updated_at": null
    },
    {
        "id": 63,
        "description": "Finland",
        "iso_two": "FI",
        "iso_three": "FIN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.048Z",
        "updated_at": null
    },
    {
        "id": 64,
        "description": "France",
        "iso_two": "FR",
        "iso_three": "FRA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.051Z",
        "updated_at": null
    },
    {
        "id": 65,
        "description": "Gabon",
        "iso_two": "GA",
        "iso_three": "GAB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.053Z",
        "updated_at": null
    },
    {
        "id": 66,
        "description": "Gambia",
        "iso_two": "GM",
        "iso_three": "GMB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.058Z",
        "updated_at": null
    },
    {
        "id": 67,
        "description": "Georgia",
        "iso_two": "GE",
        "iso_three": "GEO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.062Z",
        "updated_at": null
    },
    {
        "id": 68,
        "description": "Germany",
        "iso_two": "DE",
        "iso_three": "DEU",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.065Z",
        "updated_at": null
    },
    {
        "id": 69,
        "description": "Ghana",
        "iso_two": "GH",
        "iso_three": "GHA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.069Z",
        "updated_at": null
    },
    {
        "id": 70,
        "description": "Greece",
        "iso_two": "GR",
        "iso_three": "GRC",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.072Z",
        "updated_at": null
    },
    {
        "id": 71,
        "description": "Grenada",
        "iso_two": "GD",
        "iso_three": "GRD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.075Z",
        "updated_at": null
    },
    {
        "id": 72,
        "description": "Guatemala",
        "iso_two": "GT",
        "iso_three": "GTM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.078Z",
        "updated_at": null
    },
    {
        "id": 73,
        "description": "Guinea",
        "iso_two": "GN",
        "iso_three": "GIN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.082Z",
        "updated_at": null
    },
    {
        "id": 74,
        "description": "Guinea-Bissau",
        "iso_two": "GW",
        "iso_three": "GNB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.085Z",
        "updated_at": null
    },
    {
        "id": 75,
        "description": "Guyana",
        "iso_two": "GY",
        "iso_three": "GUY",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.088Z",
        "updated_at": null
    },
    {
        "id": 76,
        "description": "Haiti",
        "iso_two": "HT",
        "iso_three": "HTI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.091Z",
        "updated_at": null
    },
    {
        "id": 77,
        "description": "Honduras",
        "iso_two": "HN",
        "iso_three": "HND",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.095Z",
        "updated_at": null
    },
    {
        "id": 78,
        "description": "Hong Kong (SAR China)",
        "iso_two": "HK",
        "iso_three": "HKG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.098Z",
        "updated_at": null
    },
    {
        "id": 79,
        "description": "Hungary",
        "iso_two": "HU",
        "iso_three": "HUN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.101Z",
        "updated_at": null
    },
    {
        "id": 80,
        "description": "Iceland",
        "iso_two": "IS",
        "iso_three": "ISL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.125Z",
        "updated_at": null
    },
    {
        "id": 81,
        "description": "India",
        "iso_two": "IN",
        "iso_three": "IND",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.129Z",
        "updated_at": null
    },
    {
        "id": 82,
        "description": "Indonesia",
        "iso_two": "ID",
        "iso_three": "IDN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.132Z",
        "updated_at": null
    },
    {
        "id": 83,
        "description": "Iran (Islamic Republic of)",
        "iso_two": "IR",
        "iso_three": "IRN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.137Z",
        "updated_at": null
    },
    {
        "id": 84,
        "description": "Iraq",
        "iso_two": "IQ",
        "iso_three": "IRQ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.140Z",
        "updated_at": null
    },
    {
        "id": 85,
        "description": "Ireland (Republic of)",
        "iso_two": "IE",
        "iso_three": "IRL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.143Z",
        "updated_at": null
    },
    {
        "id": 86,
        "description": "Israel",
        "iso_two": "IL",
        "iso_three": "ISR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.145Z",
        "updated_at": null
    },
    {
        "id": 87,
        "description": "Italy",
        "iso_two": "IT",
        "iso_three": "ITA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.148Z",
        "updated_at": null
    },
    {
        "id": 88,
        "description": "Jamaica",
        "iso_two": "JM",
        "iso_three": "JAM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.152Z",
        "updated_at": null
    },
    {
        "id": 89,
        "description": "Japan",
        "iso_two": "JP",
        "iso_three": "JPN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.154Z",
        "updated_at": null
    },
    {
        "id": 90,
        "description": "Jordan",
        "iso_two": "JO",
        "iso_three": "JOR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.157Z",
        "updated_at": null
    },
    {
        "id": 91,
        "description": "Kazakhstan",
        "iso_two": "KZ",
        "iso_three": "KAZ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.160Z",
        "updated_at": null
    },
    {
        "id": 92,
        "description": "Kenya",
        "iso_two": "KE",
        "iso_three": "KEN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.164Z",
        "updated_at": null
    },
    {
        "id": 93,
        "description": "Kiribati",
        "iso_two": "KI",
        "iso_three": "KIR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.167Z",
        "updated_at": null
    },
    {
        "id": 94,
        "description": "Kosovo (Republic of)",
        "iso_two": "RK",
        "iso_three": "RKS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.175Z",
        "updated_at": null
    },
    {
        "id": 95,
        "description": "Kuwait",
        "iso_two": "KW",
        "iso_three": "KWT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.178Z",
        "updated_at": null
    },
    {
        "id": 96,
        "description": "Kyrgyzstan",
        "iso_two": "KG",
        "iso_three": "KGZ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.181Z",
        "updated_at": null
    },
    {
        "id": 97,
        "description": "Latvia",
        "iso_two": "LV",
        "iso_three": "LVA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.187Z",
        "updated_at": null
    },
    {
        "id": 98,
        "description": "Lebanon",
        "iso_two": "LB",
        "iso_three": "LBN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.191Z",
        "updated_at": null
    },
    {
        "id": 99,
        "description": "Lesotho",
        "iso_two": "LS",
        "iso_three": "LSO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.194Z",
        "updated_at": null
    },
    {
        "id": 100,
        "description": "Liberia",
        "iso_two": "LR",
        "iso_three": "LBR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.197Z",
        "updated_at": null
    },
    {
        "id": 101,
        "description": "Libya",
        "iso_two": "LY",
        "iso_three": "LBY",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.200Z",
        "updated_at": null
    },
    {
        "id": 102,
        "description": "Liechtenstein",
        "iso_two": "LI",
        "iso_three": "LIE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.203Z",
        "updated_at": null
    },
    {
        "id": 103,
        "description": "Lithuania",
        "iso_two": "LT",
        "iso_three": "LTU",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.206Z",
        "updated_at": null
    },
    {
        "id": 104,
        "description": "Luxembourg",
        "iso_two": "LU",
        "iso_three": "LUX",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.209Z",
        "updated_at": null
    },
    {
        "id": 105,
        "description": "Macao (SAR China)",
        "iso_two": "MO",
        "iso_three": "MAC",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.213Z",
        "updated_at": null
    },
    {
        "id": 106,
        "description": "Madagascar",
        "iso_two": "MG",
        "iso_three": "MDG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.216Z",
        "updated_at": null
    },
    {
        "id": 107,
        "description": "Malawi",
        "iso_two": "MW",
        "iso_three": "MWI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.219Z",
        "updated_at": null
    },
    {
        "id": 108,
        "description": "Malaysia",
        "iso_two": "MY",
        "iso_three": "MYS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.222Z",
        "updated_at": null
    },
    {
        "id": 109,
        "description": "Maldives",
        "iso_two": "MV",
        "iso_three": "MDV",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.225Z",
        "updated_at": null
    },
    {
        "id": 110,
        "description": "Mali",
        "iso_two": "ML",
        "iso_three": "MLI",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.251Z",
        "updated_at": null
    },
    {
        "id": 111,
        "description": "Malta",
        "iso_two": "MT",
        "iso_three": "MLT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.257Z",
        "updated_at": null
    },
    {
        "id": 112,
        "description": "Marshall Islands",
        "iso_two": "MH",
        "iso_three": "MHL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.262Z",
        "updated_at": null
    },
    {
        "id": 113,
        "description": "Mauritania",
        "iso_two": "MR",
        "iso_three": "MRT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.265Z",
        "updated_at": null
    },
    {
        "id": 114,
        "description": "Mauritius",
        "iso_two": "MU",
        "iso_three": "MUS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.267Z",
        "updated_at": null
    },
    {
        "id": 115,
        "description": "Mexico",
        "iso_two": "MX",
        "iso_three": "MEX",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.270Z",
        "updated_at": null
    },
    {
        "id": 116,
        "description": "Moldova (Republic of)",
        "iso_two": "MD",
        "iso_three": "MDA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.275Z",
        "updated_at": null
    },
    {
        "id": 117,
        "description": "Monaco",
        "iso_two": "MC",
        "iso_three": "MCO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.278Z",
        "updated_at": null
    },
    {
        "id": 118,
        "description": "Mongolia",
        "iso_two": "MN",
        "iso_three": "MNG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.281Z",
        "updated_at": null
    },
    {
        "id": 119,
        "description": "Montenegro",
        "iso_two": "ME",
        "iso_three": "MNE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.284Z",
        "updated_at": null
    },
    {
        "id": 120,
        "description": "Morocco",
        "iso_two": "MA",
        "iso_three": "MAR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.287Z",
        "updated_at": null
    },
    {
        "id": 121,
        "description": "Mozambique",
        "iso_two": "MZ",
        "iso_three": "MOZ",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.290Z",
        "updated_at": null
    },
    {
        "id": 122,
        "description": "Myanmar",
        "iso_two": "MM",
        "iso_three": "MMR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.293Z",
        "updated_at": null
    },
    {
        "id": 123,
        "description": "Namibia",
        "iso_two": "NA",
        "iso_three": "NAM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.296Z",
        "updated_at": null
    },
    {
        "id": 124,
        "description": "Nauru",
        "iso_two": "NR",
        "iso_three": "NRU",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.298Z",
        "updated_at": null
    },
    {
        "id": 125,
        "description": "Nepal",
        "iso_two": "NP",
        "iso_three": "NPL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.301Z",
        "updated_at": null
    },
    {
        "id": 126,
        "description": "Netherlands",
        "iso_two": "NL",
        "iso_three": "NLD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.304Z",
        "updated_at": null
    },
    {
        "id": 127,
        "description": "New Zealand",
        "iso_two": "NZ",
        "iso_three": "NZL",
        "is_required": 0,
        "created_at": "2024-04-24T13:14:57.307Z",
        "updated_at": null
    },
    {
        "id": 128,
        "description": "Nicaragua",
        "iso_two": "NI",
        "iso_three": "NIC",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.310Z",
        "updated_at": null
    },
    {
        "id": 129,
        "description": "Niger",
        "iso_two": "NE",
        "iso_three": "NER",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.313Z",
        "updated_at": null
    },
    {
        "id": 130,
        "description": "Nigeria",
        "iso_two": "NG",
        "iso_three": "NGA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.315Z",
        "updated_at": null
    },
    {
        "id": 131,
        "description": "North Macedonia (Republic of)",
        "iso_two": "MK",
        "iso_three": "MKD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.318Z",
        "updated_at": null
    },
    {
        "id": 132,
        "description": "Norway",
        "iso_two": "NO",
        "iso_three": "NOR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.321Z",
        "updated_at": null
    },
    {
        "id": 133,
        "description": "Oman",
        "iso_two": "OM",
        "iso_three": "OMN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.324Z",
        "updated_at": null
    },
    {
        "id": 134,
        "description": "Pakistan",
        "iso_two": "PK",
        "iso_three": "PAK",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.326Z",
        "updated_at": null
    },
    {
        "id": 135,
        "description": "Palau",
        "iso_two": "PW",
        "iso_three": "PLW",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.329Z",
        "updated_at": null
    },
    {
        "id": 136,
        "description": "Palestine",
        "iso_two": "PS",
        "iso_three": "PSE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.332Z",
        "updated_at": null
    },
    {
        "id": 137,
        "description": "Panama",
        "iso_two": "PA",
        "iso_three": "PAN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.334Z",
        "updated_at": null
    },
    {
        "id": 138,
        "description": "Papua New Guinea",
        "iso_two": "PG",
        "iso_three": "PNG",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.337Z",
        "updated_at": null
    },
    {
        "id": 139,
        "description": "Paraguay",
        "iso_two": "PY",
        "iso_three": "PRY",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.341Z",
        "updated_at": null
    },
    {
        "id": 140,
        "description": "Peru",
        "iso_two": "PE",
        "iso_three": "PER",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.344Z",
        "updated_at": null
    },
    {
        "id": 141,
        "description": "Philippines",
        "iso_two": "PH",
        "iso_three": "PHL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.348Z",
        "updated_at": null
    },
    {
        "id": 142,
        "description": "Poland",
        "iso_two": "PL",
        "iso_three": "POL",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.351Z",
        "updated_at": null
    },
    {
        "id": 143,
        "description": "Portugal",
        "iso_two": "PT",
        "iso_three": "PRT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.374Z",
        "updated_at": null
    },
    {
        "id": 144,
        "description": "Qatar",
        "iso_two": "QA",
        "iso_three": "QAT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.376Z",
        "updated_at": null
    },
    {
        "id": 145,
        "description": "Romania",
        "iso_two": "RO",
        "iso_three": "ROU",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.379Z",
        "updated_at": null
    },
    {
        "id": 146,
        "description": "Russian Federation",
        "iso_two": "RU",
        "iso_three": "RUS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.382Z",
        "updated_at": null
    },
    {
        "id": 147,
        "description": "Rwanda",
        "iso_two": "RW",
        "iso_three": "RWA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.384Z",
        "updated_at": null
    },
    {
        "id": 148,
        "description": "Saint Kitts and Nevis",
        "iso_two": "KN",
        "iso_three": "KNA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.388Z",
        "updated_at": null
    },
    {
        "id": 149,
        "description": "Saint Lucia",
        "iso_two": "LC",
        "iso_three": "LCA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.390Z",
        "updated_at": null
    },
    {
        "id": 150,
        "description": "Samoa",
        "iso_two": "WS",
        "iso_three": "WSM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.396Z",
        "updated_at": null
    },
    {
        "id": 151,
        "description": "San Marino",
        "iso_two": "SM",
        "iso_three": "SMR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.398Z",
        "updated_at": null
    },
    {
        "id": 152,
        "description": "Sao Tome and Principe",
        "iso_two": "ST",
        "iso_three": "STP",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.402Z",
        "updated_at": null
    },
    {
        "id": 153,
        "description": "Saudi Arabia",
        "iso_two": "SA",
        "iso_three": "SAU",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.404Z",
        "updated_at": null
    },
    {
        "id": 154,
        "description": "Senegal",
        "iso_two": "SN",
        "iso_three": "SEN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.407Z",
        "updated_at": null
    },
    {
        "id": 155,
        "description": "Serbia",
        "iso_two": "RS",
        "iso_three": "SRB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.410Z",
        "updated_at": null
    },
    {
        "id": 156,
        "description": "Seychelles",
        "iso_two": "SC",
        "iso_three": "SYC",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.412Z",
        "updated_at": null
    },
    {
        "id": 157,
        "description": "Sierra Leone",
        "iso_two": "SL",
        "iso_three": "SLE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.415Z",
        "updated_at": null
    },
    {
        "id": 158,
        "description": "Singapore",
        "iso_two": "SG",
        "iso_three": "SGP",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.417Z",
        "updated_at": null
    },
    {
        "id": 159,
        "description": "Slovakia",
        "iso_two": "SK",
        "iso_three": "SVK",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.420Z",
        "updated_at": null
    },
    {
        "id": 160,
        "description": "Slovenia",
        "iso_two": "SI",
        "iso_three": "SVN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.423Z",
        "updated_at": null
    },
    {
        "id": 161,
        "description": "Solomon Islands",
        "iso_two": "SB",
        "iso_three": "SLB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.426Z",
        "updated_at": null
    },
    {
        "id": 162,
        "description": "Somalia",
        "iso_two": "SO",
        "iso_three": "SOM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.428Z",
        "updated_at": null
    },
    {
        "id": 163,
        "description": "South Africa",
        "iso_two": "ZA",
        "iso_three": "ZAF",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.431Z",
        "updated_at": null
    },
    {
        "id": 164,
        "description": "South Sudan",
        "iso_two": "SS",
        "iso_three": "SSD",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.434Z",
        "updated_at": null
    },
    {
        "id": 165,
        "description": "Spain",
        "iso_two": "ES",
        "iso_three": "ESP",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.436Z",
        "updated_at": null
    },
    {
        "id": 166,
        "description": "Sri Lanka",
        "iso_two": "LK",
        "iso_three": "LKA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.439Z",
        "updated_at": null
    },
    {
        "id": 167,
        "description": "Sudan",
        "iso_two": "SD",
        "iso_three": "SDN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.442Z",
        "updated_at": null
    },
    {
        "id": 168,
        "description": "Suriname",
        "iso_two": "SR",
        "iso_three": "SUR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.444Z",
        "updated_at": null
    },
    {
        "id": 169,
        "description": "Sweden",
        "iso_two": "SE",
        "iso_three": "SWE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.447Z",
        "updated_at": null
    },
    {
        "id": 170,
        "description": "Switzerland",
        "iso_two": "CH",
        "iso_three": "CHE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.450Z",
        "updated_at": null
    },
    {
        "id": 171,
        "description": "Syrian Arab Republic",
        "iso_two": "SY",
        "iso_three": "SYR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.452Z",
        "updated_at": null
    },
    {
        "id": 172,
        "description": "Taiwan",
        "iso_two": "TW",
        "iso_three": "TWN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.455Z",
        "updated_at": null
    },
    {
        "id": 173,
        "description": "Tajikistan",
        "iso_two": "TJ",
        "iso_three": "TJK",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.458Z",
        "updated_at": null
    },
    {
        "id": 174,
        "description": "Tanzania (United Republic of)",
        "iso_two": "TZ",
        "iso_three": "TZA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.460Z",
        "updated_at": null
    },
    {
        "id": 175,
        "description": "Thailand",
        "iso_two": "TH",
        "iso_three": "THA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.463Z",
        "updated_at": null
    },
    {
        "id": 176,
        "description": "Timor-Leste",
        "iso_two": "TL",
        "iso_three": "TLS",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.490Z",
        "updated_at": null
    },
    {
        "id": 177,
        "description": "Togo",
        "iso_two": "TG",
        "iso_three": "TGO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.493Z",
        "updated_at": null
    },
    {
        "id": 178,
        "description": "Tonga",
        "iso_two": "TO",
        "iso_three": "TON",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.498Z",
        "updated_at": null
    },
    {
        "id": 179,
        "description": "Trinidad and Tobago",
        "iso_two": "TT",
        "iso_three": "TTO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.503Z",
        "updated_at": null
    },
    {
        "id": 180,
        "description": "Tunisia",
        "iso_two": "TN",
        "iso_three": "TUN",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.507Z",
        "updated_at": null
    },
    {
        "id": 181,
        "description": "Turkey",
        "iso_two": "TR",
        "iso_three": "TUR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.511Z",
        "updated_at": null
    },
    {
        "id": 182,
        "description": "Turkmenistan",
        "iso_two": "TM",
        "iso_three": "TKM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.514Z",
        "updated_at": null
    },
    {
        "id": 183,
        "description": "Tuvalu",
        "iso_two": "TV",
        "iso_three": "TUV",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.517Z",
        "updated_at": null
    },
    {
        "id": 184,
        "description": "Uganda",
        "iso_two": "UG",
        "iso_three": "UGA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.520Z",
        "updated_at": null
    },
    {
        "id": 185,
        "description": "Ukraine",
        "iso_two": "UA",
        "iso_three": "UKR",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.522Z",
        "updated_at": null
    },
    {
        "id": 186,
        "description": "United Arab Emirates",
        "iso_two": "AE",
        "iso_three": "ARE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.525Z",
        "updated_at": null
    },
    {
        "id": 187,
        "description": "United Nations Organisation",
        "iso_two": "UN",
        "iso_three": "UNO",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.530Z",
        "updated_at": null
    },
    {
        "id": 188,
        "description": "United States of America",
        "iso_two": "US",
        "iso_three": "USA",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.533Z",
        "updated_at": null
    },
    {
        "id": 189,
        "description": "Uruguay",
        "iso_two": "UY",
        "iso_three": "URY",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.536Z",
        "updated_at": null
    },
    {
        "id": 190,
        "description": "Uzbekistan",
        "iso_two": "UZ",
        "iso_three": "UZB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.539Z",
        "updated_at": null
    },
    {
        "id": 191,
        "description": "Vanuatu",
        "iso_two": "VU",
        "iso_three": "VUT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.541Z",
        "updated_at": null
    },
    {
        "id": 192,
        "description": "Vatican City State [Holy See]",
        "iso_two": "VA",
        "iso_three": "VAT",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.544Z",
        "updated_at": null
    },
    {
        "id": 193,
        "description": "Viet Nam",
        "iso_two": "VN",
        "iso_three": "VNM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.549Z",
        "updated_at": null
    },
    {
        "id": 194,
        "description": "Yemen",
        "iso_two": "YE",
        "iso_three": "YEM",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.552Z",
        "updated_at": null
    },
    {
        "id": 195,
        "description": "Zambia",
        "iso_two": "ZM",
        "iso_three": "ZMB",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.555Z",
        "updated_at": null
    },
    {
        "id": 196,
        "description": "Zimbabwe",
        "iso_two": "ZW",
        "iso_three": "ZWE",
        "is_required": 1,
        "created_at": "2024-04-24T13:14:57.557Z",
        "updated_at": null
    }
    ],
    questions: [
    {
        "id": "1",
        "question": "Are you an Australian permanent resident, with a visa that lets you return to Australia?",
        "question_min": "australian_permanent_resident",
        "parent_id": null,
        "answer": 2,
        "is_required": "1",
        "category_id": 1,
        "type_id": 1,
        "created_at": "2024-04-24T10:23:19.350Z",
        "updated_at": "2024-04-25T12:54:59.536Z",
        "alertMessage": [
            {
                "id": 2,
                "message": "When you travel to New Zealand, you must have a valid Australian permanent resident or Resident Return Visa. This will be checked when you board your plane or cruise. If you don't have this, you will be denied boarding and need to request a new NZeTA. To change your answer, select 'No'.",
                "question_id": 1,
                "answer_id": 2,
                "created_at": "2024-04-24T12:32:55.254Z",
                "updated_at": null
            },
            {
                "id": 1,
                "message": "This NZeTA can only be used if you are a transit passenger passing through Auckland International Airport to or from Australia or if you are travelling to New Zealand on a cruise. Transit passengers must remain in the transit area of Auckland International Airport.",
                "question_id": 1,
                "answer_id": 1,
                "created_at": "2024-04-24T12:32:55.253Z",
                "updated_at": null
            }
        ]
    },
    {
        "id": "2",
        "question": "Are you travelling to New Zealand on a cruise ship?",
        "question_min": "cruise_ship",
        "parent_id": 1,
        "answer": 2,
        "is_required": "1",
        "category_id": 1,
        "type_id": 3,
        "created_at": "2024-04-24T10:23:19.350Z",
        "updated_at": "2024-04-25T12:54:59.537Z",
        "alertMessage": []
    },
    {
        "id": "3",
        "question": "Are you a transit passenger passing through Auckland International Airport on your way to or from Australia?",
        "question_min": "transit_passenger",
        "parent_id": 2,
        "answer": 2,
        "is_required": "1",
        "category_id": 1,
        "type_id": 3,
        "created_at": "2024-04-24T10:26:59.277Z",
        "updated_at": "2024-04-25T12:54:59.538Z",
        "alertMessage": [
            {
                "id": 3,
                "message": "You are not eligible for an NZeTA. Apply for a Visitor Visa.",
                "question_id": 3,
                "answer_id": 1,
                "created_at": "2024-04-24T12:33:35.610Z",
                "updated_at": null
            }
        ]
    },
    {
        "id": "4",
        "question": "Will you be travelling to New Zealand for medical consultation or treatment?",
        "question_min": "travel_medical",
        "parent_id": null,
        "answer": 1,
        "is_required": "1",
        "category_id": 2,
        "type_id": 4,
        "created_at": "2024-04-25T12:57:57.345Z",
        "updated_at": null,
        "alertMessage": []
    },
    {
        "id": "5",
        "question": "Have you ever been deported, removed or excluded from another country (not New Zealand)?",
        "question_min": "deported",
        "parent_id": null,
        "answer": 1,
        "is_required": "1",
        "category_id": 2,
        "type_id": 1,
        "created_at": "2024-04-25T12:57:57.347Z",
        "updated_at": null,
        "alertMessage": []
    },
    {
        "id": "6",
        "question": "Are you currently prohibited from entering New Zealand following deportation from New Zealand in the past?",
        "question_min": "prohibited",
        "parent_id": null,
        "answer": 1,
        "is_required": "1",
        "category_id": 2,
        "type_id": 1,
        "created_at": "2024-04-25T12:57:57.348Z",
        "updated_at": null,
        "alertMessage": []
    },
    {
        "id": "7",
        "question": "Have you ever been convicted of any offence (in any country)?",
        "question_min": "convicted_offence",
        "parent_id": null,
        "answer": 1,
        "is_required": "1",
        "category_id": 2,
        "type_id": 1,
        "created_at": "2024-04-25T13:00:39.955Z",
        "updated_at": null,
        "alertMessage": []
    },
    {
        "id": "8",
        "question": "Have you ever been convicted of an offence for which you were sentenced to five years or more imprisonment?",
        "question_min": "convicted_5years_more",
        "parent_id": 7,
        "answer": 1,
        "is_required": "1",
        "category_id": 2,
        "type_id": 1,
        "created_at": "2024-04-25T13:01:47.503Z",
        "updated_at": null,
        "alertMessage": [
            {
                "id": 4,
                "message": "You are not eligible for an NZeTA. Apply for a Visitor Visa.",
                "question_id": 8,
                "answer_id": 2,
                "created_at": "2024-04-24T12:33:35.610Z",
                "updated_at": null
            }
        ]
    },
    {
        "id": "9",
        "question": "In the last 10 years have you been convicted of an offence for which you were sentenced to a prison term of 12 months or more?",
        "question_min": "prison_term_12mon_more",
        "parent_id": 7,
        "answer": 1,
        "is_required": "1",
        "category_id": 2,
        "type_id": 1,
        "created_at": "2024-04-25T13:02:46.097Z",
        "updated_at": null,
        "alertMessage":  [
            {
                "id": 5,
                "message": "You are not eligible for an NZeTA. Apply for a Visitor Visa.",
                "question_id": 9,
                "answer_id": 2,
                "created_at": "2024-04-24T12:33:35.610Z",
                "updated_at": null
            }
        ]
    },
    {
        "id": "10",
        "question": "Will you be staying in New Zealand?",
        "question_min": "staying_in_new_zealand",
        "parent_id": 1,
        "answer": null,
        "is_required": "1",
        "category_id": 1,
        "type_id": 2,
        "created_at": "2024-04-24T10:26:59.277Z",
        "updated_at": "2024-04-25T12:54:59.538Z",
        "alertMessage": [
            {
                "id": 6,
                "message": "If you choose this option your NZeTA will be valid for transit only. If you plan to leave the airport and enter New Zealand, you must indicate this on your NZeTA request and pay the International Visitor Conservation and Tourism Levy (IVL). To change your answer, select 'Yes'.",
                "question_id": 10,
                "answer_id": 1,
                "created_at": "2024-04-24T12:33:35.610Z",
                "updated_at": null
            }
        ],

    },
],
    answers: [
        {
            "id": 1,
            "description": "No",
            "created_at": "2024-04-24T10:21:33.897Z",
            "updated_at": "2024-04-24T10:22:05.694Z"
        },
        {
            "id": 2,
            "description": "Yes",
            "created_at": "2024-04-24T10:21:33.897Z",
            "updated_at": "2024-04-24T10:22:05.695Z"
        }
    ],
    question_type: [
        {
            "id": 1,
            "description": "For All Countries",
            "created_at": "2024-04-24T10:21:33.897Z",
            "updated_at": "2024-04-24T10:22:05.694Z"
        },
        {
            "id": 2,
            "description": "For Allowed Transit and Stay",
            "created_at": "2024-04-24T10:21:33.897Z",
            "updated_at": "2024-04-24T10:22:05.695Z"
        },
        {
            "id": 3,
            "description": "For Allowed Transit only",
            "created_at": "2024-04-24T10:21:33.897Z",
            "updated_at": "2024-04-24T10:22:05.695Z"
        },
        {
            "id": 4,
            "description": "For Allowed Medication",
            "created_at": "2024-04-24T10:21:33.897Z",
            "updated_at": "2024-04-24T10:22:05.695Z"
        }
    ],
    activePicker1: null,
    menu1: false,
    restricted: false,
    loadingConfirm: false,
    triggered: false,
    eligible: false
    }),
    watch: {
    formdata: {
        handler () {
         this.$refs.formRef.resetValidation()
        this.edit();
        },
        deep: true,
      },
    clickedNext(val) {
        console.log("clicked-next", val)

        const el = document.querySelector(".error--text");
        if(el){
        this.$vuetify.goTo(".error--text", { offset: 60 })
        }
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    currentStep(val) {
        console.log("current-step", val)
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    menu1(val) {
        val && setTimeout(() => (this.activePicker1 = 'YEAR'))
    },
    },
    mounted() {
        this.$refs.formRef.resetValidation()
        if(this.$store.state.formdata != null){
        let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata));
        this.formdata.passport = JSON.parse(JSON.stringify(formdata.passport));
        this.formdata.residency_questions = JSON.parse(JSON.stringify(formdata.residency_questions));
        this.formdata.eligibility_questions = JSON.parse(JSON.stringify(formdata.eligibility_questions))
        this.updateVariable();
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
        const el = document.querySelector("#stepper-unique-div");
            el.scrollIntoView({
                behavior: 'smooth'
            })
            return;
        }
    },
    methods: {
        save (date) {
            this.$refs.menu1.save(date)
        },
        getSeparatedQuestions(){
        const listToTree  = (arr = []) => {
        let map = {}, node, res = [], i;
        for (i = 0; i < arr.length; i += 1) {
            map[arr[i].id] = i;
            arr[i].follow_up = [];
        }
        for (i = 0; i < arr.length; i += 1) {
            node = arr[i];
            if (node.parent_id !== null) {
                arr[map[node.parent_id]].follow_up.push(node);
            }
            else {
                res.push(node);
            }
        }
        return res;
        };
        let questions_temp = [];
        this.questions.forEach(item => {
            if(this.checkQuestionPerNationality(item.type_id)){
                questions_temp.push(item)
            }
        });
            let questions_final = listToTree(questions_temp)
        questions_final.forEach(item => {
            if(item.category_id == 1){
                this.formdata.residency_questions.push(item);
            }
            else if(item.category_id == 2){
                this.formdata.eligibility_questions.push(item);
            }
          });
        //   console.log("residency", this.formdata.residency_questions)
        //   console.log("eligibility", this.formdata.eligibility_questions)
        },
        getValidDates(){
            var date = new Date(Date.now());
            date.setDate(date.getDate() + 1);
            return date.toISOString().substring(0, 10);
        },
        updateVariable(){
            let temp = JSON.parse(JSON.stringify(this.formdata))
            let temp1 = JSON.parse(JSON.stringify(this.formdata.residency_questions))
            let temp2 = JSON.parse(JSON.stringify(this.formdata.eligibility_questions))
            this.formdata = [];
            this.formdata.residency_questions = [];
            this.formdata.eligibility_questions = [];
            this.formdata = temp;
            this.formdata.residency_questions = temp1;
            this.formdata.eligibility_questions = temp2;
            this.eligible = true
            this.formdata.residency_questions.forEach(question => {
                if(question.answer != question.choice && question.follow_up.length > 0){
                    this.eligible = false;
                }
                if(!this.eligible){
                    this.eligible = true;
                question.follow_up.forEach( follow_up1 => {
                        if(follow_up1.answer != follow_up1.choice && follow_up1.follow_up.length > 0){
                            this.eligible = false;
                        }
                        if(!this.eligible){
                            this.eligible = true;
                        follow_up1.follow_up.forEach(follow_up2 => {
                            if(follow_up2.answer != follow_up2.choice){
                                this.eligible = false;
                            }
                        });
                        }
                    });
                }
            });
        },
        checkNationality(){
            let result = false;
            this.countries.forEach(item => {
            if(this.formdata.passport.nationality == item.id && item.is_required == 0){
                result = true
            }
        });
            this.restricted = result;
            if(result == false){
                this.formdata.residency_questions = [];
                this.formdata.eligibility_questions = [];
                this.getSeparatedQuestions();
            }
            this.$refs.formRef.resetValidation()
        },
        confirm(){
            if(this.$refs.formRef.validate()){
            let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata || {}));
            formdata.passport = JSON.parse(JSON.stringify(this.formdata.passport));
            formdata.residency_questions = JSON.parse(JSON.stringify(this.formdata.residency_questions));
            formdata.eligibility_questions = JSON.parse(JSON.stringify(this.formdata.eligibility_questions));
            this.$store.dispatch("setFormdata", formdata);
            this.$emit("can-continue", {value: true});
            }
            else {
                this.$emit("can-continue", {value: false});
                this.$emit('change-next', {nextBtnValue: false});
                // this.$refs.formRef.resetValidation()
            }
        },
        edit(){
            this.$emit("can-continue", {value: false});
            this.$emit('change-next', {nextBtnValue: false});
            setTimeout(() => {
            document.getElementById("confirm_btn").click();
            }, 0);
        },
        reset(){
            this.formdata = {
                passport: {
                    nationality: null,
                    territory: null,
                    number: null,
                    expiry_date: null,
                },
                residency_questions: [],
                eligibility_questions: [],
            };
            this.getSeparatedQuestions();
            this.$refs.formRef.resetValidation()
        },
        checkQuestionPerNationality(type_id){
            let country_allow_transit_and_stay = this.$store.state.country_allow_transit_and_stay;
            // let country_no_medication = this.$store.state.country_no_medication;
            let result = false;
            let nationality = this.countries.find((country) => country.id == this.formdata.passport.nationality).description;
            if(type_id == 1){
                result = true;
            }
            else if(type_id == 2){
                result = country_allow_transit_and_stay.includes(nationality);
            }
            else if(type_id == 3){
                result = country_allow_transit_and_stay.includes(nationality) ? false : true;
            }
            else if(type_id == 4){
                // result = country_no_medication.includes(nationality) ? false : true;
                result = true;
            }
            return result
        }
    },
  }
</script>
<style scoped>
.asterisk{
    color: #dc2a5f;
    font-size: 20px;
    margin-left: 7px;
}
</style>