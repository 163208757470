import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store';
import OuterLayout from '../layouts/OuterLayout.vue';
import Application from '../views/Pages/Application.vue';
import ThankYou from '../views/Pages/ThankYou.vue';
import TermsOfUse from '../views/Pages/TermsOfUse.vue';
import PrivacyPolicy from '../views/Pages/PrivacyPolicy.vue';
import CookiePolicy from '../views/Pages/CookiePolicy.vue';
import Disclaimers from '../views/Pages/Disclaimers.vue';
import DigitalServiceAct from '../views/Pages/DigitalServiceAct.vue';
import Payment from '../views/Pages/Payment.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        meta: { RouteForTraveller: true },
        component: OuterLayout,
        children: [
            {
                path: '/terms-of-use/',
                component: TermsOfUse
                },
                {
                path: '/privacy-policy/',
                component: PrivacyPolicy
                },
                {
                path: '/cookie-policy/',
                component: CookiePolicy
                },
                {
                path: '/disclaimers/',
                component: Disclaimers
                },
                {
                path: '/digital-services-act/',
                component: DigitalServiceAct
                },
                {
                path: '/about-us/',
                alias: '/about-us/:aff_id:cid',
                redirect: '/'
                },
                {
                path: '/services/',
                alias: '/services/:aff_id:cid',
                redirect: '/'
                },
                {
                path: '/contact/',
                alias: '/contact/:aff_id:cid',
                redirect: '/'
                },
                {
                path: '/application/:token/',
                component: Application,
                },
                {
                path: '/application/:aff_id:cid',
                component: Application,
                },
                {
                path: '/thank-you/:token/',
                component: ThankYou
                },
                {
                path: '/application/:token/payment/',
                name: 'payment',
                component: Payment,
                },
                // {
                // path: '/payment/:token/',
                // redirect: '/application/:token/'
                // },
                {
                path: '/docupass/:token/',
                // redirect: '/application/:token/'
                },
                {
                path: '*',
                component: Application,
                },
            ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    // var token = localStorage.getItem('token');
    if (to.matched.some(record => record.meta.RouteForTraveller)) {
        next()
    }
    // else {
    //     next({path: '/'})
    // }
})

export default router